<template>
<section class="repair cnt">
  <div class="repair__container" id="repair" v-if="isDesctop">
    <FlyingObj top="-80px" left="-70px" name="flying-object-6"/>
    <div class="repair__wrap">
      <div class="repair__text">
        <h2 class="repair__title title-sect">Рассчитаем
          ремонт</h2>
        <p class="repair__descr descr-sect">Доверьте свой автомобиль профессионалам. Запишитесь на техническое обслуживание всего за несколько минут, и мы свяжемся с вами.</p>
      </div>

      <div class="repair__content">
        <form class="repair__form" @submit.prevent>

          <div class="repair__field">
            <div class="repair__label">
              VIN:
            </div>
            <CustomInput placeholder="VIN-номер машины" v-model="vin"/>
          </div>

          <div class="repair__field">
            <div class="repair__label">
              ФИО:
            </div>

            <CustomInput placeholder="Иванов Иван Иванович" @removeError="errorName = false" :error="errorName" v-model="name"/>
          </div>
          <div></div>
          <div class="repair__field">
            <div class="repair__label">
              Коротко опишите проблему:
            </div>
            <CustomInput placeholder="Поле для ввода поломки" v-model="breaking"/>
          </div>


          <div class="repair__field">
            <div class="repair__label">
              Укажите телефон:
            </div>
            <CustomInput :error="errorTel" mask="+ 7 (###) ###-##-##" @removeError="errorTel = false" placeholder="+ 7 ( ___ ) ___ - __ - __" v-model="tel"/>
          </div>


          <div class="repair__field repair__field--sm repair__field--one-elem">
            <button class="btn repair__btnSend" @click.prevent="sendTicket">Отправить</button>
          </div>


        </form>
          <div class="repair__politic">
            <div class="repair__politic-text">Нажимая кнопку "Отправить", Вы соглашаетесь с <a href="politic" class="repair__politic-href" target="_blank">Политикой защиты данных</a></div>
          </div>
      </div>
    </div>
  </div>
  <div class="repair__container repair__container--sm" id="repair" v-else>
    <FlyingObj top="-80px" left="-70px" name="flying-object-6"/>
    <div class="repair__wrap--sm">
      <h2 class="repair__title--sm">Рассчитаем
        ремонт</h2>
      <p class="repair__descr--sm">Доверьте свой автомобиль профессионалам. Запишитесь на техническое обслуживание всего за несколько минут, и мы свяжемся с вами.</p>
    </div>
    <button class="btn repair__btn--sm" @click="openPopup">
      Заполнить форму расчета онлайн
      <SvgIcon class="repair__btnSvg--sm" name="arrow"/>
    </button>
  </div>
</section>
</template>

<script>
import FlyingObj from "../Object/FlyingObj";
import CustomInput from "../elements/CustomInput";
import SvgIcon from "../SvgIcon";
import axios from "axios"
import {bus} from "../bus";

export default {
  name: "RepairCalc",
  components: {
    FlyingObj,
    CustomInput,
    SvgIcon
  },
  data() {
    return {
      vin: '',
      errorVin: false,
      breaking: '',
      errorBreaking: false,
      tel: '',
      errorTel: false,
      name: '',
      errorName: false,
      isDesctop: true
    }
  },
  mounted() {
    this.isDesctop = window.innerWidth > 1024;
  },
  methods: {
    openPopup() {
      bus.$emit('togglePopup', {
        title: 'Оставьте заявку на рассчет ремонта',
        btnText: 'Оставить'
      });
    },
    sendTicket() {
      this.errorTel = false;
      this.errorName = false;
      if (this.name === '')
        this.errorName = true
      if(this.tel.length !== 19) {
        this.errorTel = true;
      }
      if (this.errorTel || this.errorName)
        return
      axios.post('/mail.php', {
        phone: this.tel,
        name: this.name,
        vin: this.vin,
        breaking: this.breaking,
        code: 'repair'
      }).then(({data}) => {
        this.tel = '';
        this.name = '';
        this.breaking = '';
        this.vin = '';
        if (data.status === 'success') {
          bus.$emit('toggleSuccessPopup');
        } else {
          bus.$emit('toggleSuccessPopup', 'Ошибка при отправке заявки');
        }
      }).catch(() => {
        bus.$emit('toggleSuccessPopup', 'Ошибка при отправке заявки');
      })
    }
  }
}
</script>

<style lang="scss">
.style-chooser .vs__dropdown-menu {
  z-index: 1000;
  max-height: 200px;
}
.repair {
  min-height: 250px;
  position: relative;
  @include below($desktopDef) {
    min-height: 300px;
  }
  @include below($desktopSml) {
    min-height: 200px;
  }
  &__container {
    padding: 50px 40px;
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(22px);
    position: absolute;
    right: 15px;
    left: 15px;
    top: -115px;
    @include below($desktopSml) {
      top: -85px;
    }
    @include below($desktopDef) {
      padding: 25px 20px;
    }
    &--sm {
      padding: 40px 0 0;
    }
  }
  &__content {
    width: calc(100% * (2/3));
  }
  &__text {
     width: calc(100% / 3);
     margin-right: 30px;
   }
  &__title {
    line-height: 90%;
    letter-spacing: 0.01em;
    color: #33353B;
    &--sm {
      font-weight: 800;
      font-size: 48px;
      line-height: 90%;
      letter-spacing: 0.01em;
      color: #33353B;
      @include below($mobileDef) {
      font-size: 36px;
    }
    }

  }
  &__descr {
    color: #33353B;
    &--sm {
      margin-top: 7px;
      margin-bottom: 35px;
      font-size: 14px;
      line-height: 120%;
      color: #33353B;
    }
  }
  &__politic{
    &-text{
      font-weight: 500;
      font-size: 12px;
      color: #93949B;
    }
    &-href{
      text-decoration: underline;
    }
    &-href:hover{
      text-decoration: none;
    }
  }
  &__btn--sm {
    border-radius: 0;
    width: 100%;
    background: #ef850d;
    padding: 15px 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__btnSvg--sm {
    margin-left: 7px;
    transform: rotate(180deg);
  }
  &__wrap {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    &--sm {
      padding: 0 30px;
    }
  }
  &__form {
    display: flex;
    flex-wrap: wrap;
  }
  &__field {
    width: calc(37.5% - 20px);
    margin-right: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &--sm {
      width: 25%;
    }
    &--one-elem {
      justify-content: flex-end;
    }
    &:nth-child(3n) {
      margin-right: 0;
    }
  }
  &__label {
    font-weight: bold;
    font-size: 18px;
    color: #1C2044;
    margin-bottom: 14px;
  }
  &__btnSend {
    padding: 14px 0;
  }
}
.radio {
  display: flex;
  flex-wrap: wrap;
  &__elem {
    display: flex;
    width: calc(50% - 10px);
    margin-bottom: 10px;
    margin-right: 10px;
    &:last-child {
      margin-bottom: 0px;
      width: 100%;
    }
    input {
      position: absolute;
      z-index: -1;
      opacity: 0;
      margin: 10px 0 0 7px;
      width: 10px;
      &:checked + label:after {
        opacity: 1;
      }
      &+ label {
        position: relative;
        cursor: pointer;
        padding: 0 0 0 35px;
        &:before {
          content: '';
          position: absolute;
          top: 0px;
          left: 0;
          background: #FFFFFF;
          width: 20px;
          height: 20px;
          border: 1px solid #C9C9C9;
          box-sizing: border-box;
          border-radius: 5px;
        }
        &:after {
          content: url('~@/assets/images/svg/check.png');
          display: flex;
          justify-content: center;
          position: absolute;
          top: 0px;
          left: 0px;
          background: #454559;
          border-radius: 5px;
          width: 20px;
          height: 20px;
          opacity: 0;
          transition: .2s;
        }
      }
    }
  }
}
</style>
