<template>
  <nav class="nav" v-if="Array.isArray(list) && list.length">
    <a  :key="i"
        class="nav__item"
        v-scroll-to="item.href"
        v-for="(item, i) in list">
      {{ item.title }}
    </a>
  </nav>
</template>

<script>
export default {
  name: "FooterNav",
  props: {
    list: {
      type: Array,
      default() {
        return []
      }

    }
  }
}
</script>

<style lang="scss" scoped>
.nav {
  display: flex;
  flex-wrap: wrap;
  &__item {
    display: block;
    font-weight: bold;
    font-size: 16px;
    color: #fff;
    margin-right: 30px;
    margin-bottom: 15px;
    width: calc(100% / 3);
    cursor: pointer;
  }
}
</style>
