<template>
<div class="calc">
  <FlyingObj :hidden1200="true" :hidden-xl="true" top="-8px" left="-58px" z-index="-1" name="flying-object-3"/>
  <FlyingObj :hidden1200="true" :hidden-xl="true" top="-8px" right="-80px" z-index="-1" name="flying-object-3"/>
  <div class="calc__edgeLeftTop"></div>
  <div class="calc__edgeRightBottom"></div>
  <form class="calc__form">
    <div class="calc__inputWrap">
      <label class="calc__input">
        Укажите в какой части автомобиля произошла поломка:
      </label>
      <div class="calc__select">
        <v-select
            v-model="valuePart"
            :placeholder="valuePart"
            :options="optionsPartBreak"
            :searchable="false"
            :clearable="false"
        ></v-select>
      </div>
    </div>
    <div class="calc__inputWrap">
      <label class="calc__input">
        Какая деталь работает
        некорректно?
      </label>
      <div class="calc__select">
        <v-select
            :searchable="false"
            :clearable="false"
            v-model="valueBreak"
            :placeholder="valueBreak"
            :options="optionsBreak" ></v-select>
      </div>
    </div>
     <button class="btn calc__btn">Узнать причину</button>
  </form>
  <div class="calc__line"></div>
  <div class="calc__output">
    <SvgIcon class="calc__output-car" name="car-calc"/>
    <div class="calc__help">Окно для вывода
      результатов</div>
  </div>
</div>
</template>

<script>
import SvgIcon from "../SvgIcon";
import FlyingObj from "../Object/FlyingObj";

export default {
  name: "BreakCalc",
  data () {
    return {
      valuePart: 'В салоне машины',
      valueBreak: 'Кондиционер',
      optionsPartBreak: ['В салоне машины', 'На корпусе машины', 'В системе охлаждения'],
      optionsBreak: ['Кондиционер', 'Радиатор', 'Свечи', 'Двигатель']
    }
  },
  components: {
    SvgIcon,
    FlyingObj
  }
}
</script>

<style lang="scss">

.calc {
  background: rgb(247,247,247);
  background: linear-gradient(90deg, rgba(247,247,247,1) 0%, rgba(247,247,247,1) 50%, rgba(247,247,247,1) 50%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%);
  box-shadow: 0px 0px 8px rgba(34, 34, 34, 0.15);
  border-radius: 6px;
  width: calc(100% * (2/3));
  position: relative;
  padding: 40px 50px;
  display: flex;
  @include below($desktopSml) {
    background: #F3F3F3;
    width: 60%;
  }
  @include below($desktopDef) {
    padding: 30px 30px;
  }
  @include below($mobileLrg) {
    margin-top: 20px;
    width: 100%;
    margin-right: 0;
    padding: 22px 15px 27px;
  }
  &__inputWrap {
    margin-bottom: 25px;
  }
  &__input {
    margin-bottom: 25px;
    font-weight: bold;
    font-size: 18px;
    line-height: 120%;
    color: #1C2044;
  }
  &__form {
    width: 50%;
    @include below($desktopSml) {
      width: 100%;
    }
  }
  &__line {
    position: absolute;
    height: 100%;
    border: 2px dashed #E9E9E9;
    width: 0px;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    @include below($desktopSml) {
      display: none;
    }
  }
  &__output {
    position: relative;
    padding-left: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FFFFFF;
    width: 50%;
    @include below($desktopSml) {
      display: none;
    }
  }
  &__output-car {
    height: 326px;
    width: 326px;
  }
  &__help {
    position: absolute;
    top: calc(50% - 30px);
    left: 0;
    right: 0;
    margin: auto;

    font-weight: bold;
    font-size: 18px;
    text-align: center;
    color: #181A33;
    max-width: 230px;
    padding-left: 50px;
  }
  &__edgeLeftTop {
    position: absolute;
    top: 8px;
    left: 8px;
    border-top: 2.4px solid #ef850d;
    border-left: 2.4px solid #ef850d;
    border-top-left-radius: 4px;
    width: 22px;
    height: 20px;
  }
  &__edgeRightBottom {
    position: absolute;
    bottom: 8px;
    right: 8px;
    border-bottom: 2.4px solid #ef850d;
    border-right: 2.4px solid #ef850d;
    border-bottom-right-radius: 4px;
    width: 22px;
    height: 20px;
  }
  &__btn {
    padding: 15px 0;
    width: 280px;
    background: linear-gradient(92.09deg, #262948 13.65%, #171A33 91.9%);
    @include below($desktopSml) {
      width: 100%;
    }
  }
  &__select {
    margin-top: 15px;
    width: 280px;
    @include below($desktopSml) {
      width: 100%;
    }
  }
}
.vs__selected {
  font-weight: normal;
  font-size: 16px;
  color: #595959;
}
.vs__dropdown-toggle {
  border-radius: 6px !important;
  padding: 10px !important;
  border: 1px solid #E3E3E3 !important;

  background: #FFFFFF !important;
  overflow: hidden;

  font-size: 16px;
  color: #595959;
}
</style>