<template>
<section class="option">
  <div class="cnt">
    <div class="option__wrap">
      <div class="option__text">
        <h2 class="option__title title-sect">Знаем причину</h2>
        <p class="option__descr descr-sect">Не заводится автомобиль? Не “дует” кондиционер? Двигатель превратился в
          “масложор”? <br>Специалисты нашего сервиса, исходя из опыта, уже знают причину!</p>
      </div>
      <BreakCalc/>
    </div>
  </div>
  <FlyingObj :hidden-xl="true" bottom="-110px" left="50px" name="flying-object-1"/>
</section>
</template>

<script>
import FlyingObj from "../Object/FlyingObj";
import BreakCalc from "../Calc/BreakCalc";

export default {
  name: "ModelOption",
  components: {
    FlyingObj,
    BreakCalc
  }
}
</script>

<style lang="scss" scoped>
.option {
  position: relative;
  padding: 35px 0;
  @include below($desktopSml) {
    padding-top: 50px;
  }
  &__text {
    width: calc(100% / 3);
    margin-right: 30px;
    @include below($desktopSml) {
      width: calc(40% - 30px);
    }
    @include below($mobileLrg) {
      width: 100%;
      margin-right: 0;
    }
  }
  &__title {
    line-height: 90%;
    letter-spacing: 0.01em;
    color: #ef850d;
  }
  &__descr {
    margin-top: 15px;
    line-height: 120%;
    color: #11163F;
  }
  &__wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include below($mobileLrg) {
      flex-direction: column;
    }
  }
}
</style>