<template>
<div class="works">
  <div class="cnt">
    <h2 class="works__title-main">Наши работы</h2>
    <p class="works__desc">Предлагаем вам ознакомиться с видами работ,<br> которые мы оказываем в «Звезде Невы».</p>
    <div class="works__content">
      <div class="works__item" v-for="(item, idx) in items" :key="idx">
        <img :src="item.img" :alt="item.title" class="works__img">
        <div class="works__head">
          <div class="works__title">{{ item.title }}</div>
          <div class="works__more" @click="openPopup">Подробнее</div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {bus} from "@/components/bus";

export default {
  name: "ModelWorks",
  data () {
    return {
      items: [
        {
          active: false,
          img: "/img/works/1.jpg",
          title: "Промывка двигателя",
          text: "Предлагаем вам ознакомиться с видами работ, которые мы оказываем в «Народном сервисе». Благодаря большому ряду преимуществ компании «Народный сервис» вы можете смело доверить свой автомобиль нашим опытным специалистам."
        },
        {
          active: false,
          img: "/img/works/2.jpg",
          title: "Чистка дроссельной заслонки",
          text: "Предлагаем вам ознакомиться с видами работ, которые мы оказываем в «Народном сервисе». Благодаря большому ряду преимуществ компании «Народный сервис» вы можете смело доверить свой автомобиль нашим опытным специалистам.",
        },
        {
          active: false,
          img: "/img/works/3.jpg",
          title: "Профилактика тормозной системы",
          text: "Предлагаем вам ознакомиться с видами работ, которые мы оказываем в «Народном сервисе». Благодаря большому ряду преимуществ компании «Народный сервис» вы можете смело доверить свой автомобиль нашим опытным специалистам."
        }
      ]
    }
  },
  methods: {
    openPopup() {
      bus.$emit('togglePopup', {
        title: 'Оставьте свои контактные данные и мы предложим удобное время для записи',
        btnText: 'Отправить'
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.works {
  background: #515151;
  padding: 40px 0 150px;
  color: white;
  @include below($desktopSml) {
    padding-bottom: 120px;
  }
  &__title-main {
    margin-bottom: 10px;
    font-weight: 800;
    font-size: 60px;
    @include below($desktopSml) {
      font-size: 40px;
    }
    @include below($tablet) {
      font-size: 28px;
    }
  }
  &__desc {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 20px;
    @include below($desktopSml) {
      margin-bottom: 15px;
    }
    @include below($tablet) {
      font-size: 14px;
    }
  }
  &__more {
    display: flex;
    justify-content: flex-end;
  }
  &__content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 38px;
    @include below($desktopSml) {
      grid-template-columns: 1fr;
    }
  }
  &__item {
    position: relative;
    width: 100%;
  }
  &__img {
    border-radius: 6px;
    overflow: hidden;
    object-fit: cover;
    margin-bottom: 20px;
    width: 100%;
    height: 300px;
  }
  &__title {
    font-weight: 500;
    font-size: 18px;
  }
  &__head {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    @include below($tablet) {
      grid-template-columns: 60% 40%;
    }
  }
  &__more {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-decoration-line: underline;
    color: #B5B5B7;
    cursor: pointer;
  }
  &__text {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 20px;
  }
  &__btn {
    padding: 13px 60px;
  }
  &__btm {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    transform: translateY(100%);
    @include below($desktopSml) {
      position: relative;
      transform: none;
      margin-top: 10px;
    }
  }
}
</style>
