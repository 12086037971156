import Vue from 'vue'
import '@/assets/scss/main.scss'
import App from './App.vue'
import router from './router'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import YmapPlugin from 'vue-yandex-maps';
import VueScrollTo from 'vue-scrollto';

Vue.use(YmapPlugin)
Vue.use(VueScrollTo, {
	duration: 750,
	easing: "ease",
	offset: -100
})

Vue.config.productionTip = false

Vue.component('v-select', vSelect)

new Vue({
	router,
	render: h => h(App)
}).$mount('#app')





