<template>
<div class="model-calc">
  <img src="/img/model-calc.png" class="model-calc__bg" alt="model calc bg">
  <div class="cnt model-calc__cnt">
    <h2 class="model-calc__title title">Онлайн расчёт стоимости кузовного ремонта</h2>
    <a href="#" id="widget-btn" class="btn model-calc__btn">
      Рассчитать
    </a>
  </div>
</div>
</template>

<script>
export default {
  name: "ModelCalc"
}
</script>

<style lang="scss" scoped>
.model-calc {
  position: relative;
  background-color: #515151;
  height: 400px;
  display: flex;
  align-items: center;
  @include below($desktopSml) {
    height: 300px;
  }
  @include below($tablet) {
    height: unset;
    padding: 60px 0;
  }
  @include below($mobileDef) {
    padding: 40px 0;
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background: linear-gradient(90.2deg, #515151 61.43%, rgba(81, 81, 81, 0.81) 66.33%, rgba(81, 81, 81, 0) 80.51%);
    width: 80%;
    height: 100%;
    @include below($tablet) {
      width: 100%;
      background: rgba(0, 0, 0, .6);
    }
  }
  &__cnt {
    position: relative;
    z-index: 2;
  }
  &__title {
    max-width: 60%;
    font-weight: 800;
    font-size: 60px;
    color: #FFFFFF;
    line-height: 0.9;
    @include below($desktopSml) {
      font-size: 40px;
    }
    @include below($tablet) {
      font-size: 28px;
      max-width: 100%;
    }
  }
  &__btn {
    display: inline-flex;
    padding: 13px 64px;
    margin-top: 60px;
    @include below($desktopSml) {
      margin-top: 30px;
    }
  }
  &__bg {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 60%;
    object-fit: cover;
    object-position: left;
    @include below($tablet) {
      width: 100%;
    }
  }
}
</style>
