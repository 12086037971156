<template>
  <div class="headerFixed">
    <div class="headerFixed__top">
      <div class="headerFixed__cnt">
        <div class="headerFixed__left">
          <div class="headerFixed__address">
            Витебский проспект, 17к7, г. Санкт-Петербург
          </div>
          <a href="https://yandex.ru/maps/?ll=30.353687%2C59.870782&mode=routes&rtext=~59.870782%2C30.353687&rtt=auto&z=15" target="_blank" class="headerFixed__routeSet headerFixed__routeSet--desctop">
            <SvgIcon class="headerFixed__icon" name="compass-white"/>
            <span class="headerFixed__routeText">Проложить маршрут</span>
          </a>
        </div>
        <div class="headerFixed__right">
          <div class="headerFixed__worktime headerFixed__worktime--desctop">
            Пн-Вс — 9:00-21:00
          </div>
          <a href="tel:+78124456060" class="headerFixed__tel">+7 812 445-60-60</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from "../SvgIcon";
import {bus} from "../bus";


export default {
  name: "HeaderFixed",
  components: {
    SvgIcon
  },
  data() {
    return {
      navList: [
        {
          id: 0,
          title: 'Акции',
          href: '#promo'
        },
        // {
        //   id: 1,
        //   title: 'Калькулятор неисправностей',
        //   href: '#repair-calc'
        // },
        {
          id: 3,
          title: 'Услуги',
          href: '#modelService'
        },
        {
          id: 4,
          title: 'Быстрая запись',
          href: '#repair'
        },
        {
          id: 5,
          title: 'Галерея',
          href: '#gallery'
        },
        {
          id: 6,
          title: 'Контакты',
          href: '#map'
        }
      ],
      isActiveMenu: false
    }
  },
  methods: {
   toggleMenu() {
      this.isActiveMenu = !this.isActiveMenu;
    },
    openPopup() {
      bus.$emit('togglePopup', {
        title: 'павып',
        btnText: 'fdsds'
      });
    }
  }
}
</script>
<style>
.fade-enter-active, .fade-leave-active {
  transition: transform .7s;
}
.fade-enter, .fade-leave-to {
  transform: translateY(-100%);
}
</style>
<style lang="scss" >
.headerFixed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  &__cnt {
    position: relative;
    height: 100%;
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 17px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 1000;
    background-color: $headerBG-color;
    color: white;
  }
  &__wrap {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 1000;
  }
  &__top {
    position: relative;
    z-index: 99;
    height: 50px;
    border-bottom: 1px solid rgba(0, 0, 0, .2);
    background-color: $headerBG-color;
  }

  &__btm {
    margin-top: 49px;
    position: sticky;
    top: 0px;
    z-index: 99;
    height: 80px;
    width: 100%;
  }

  &__logo {
    display: flex;
    align-items: center;
    transition: opacity 0.15s ease-in;
    @include below($desktopSml) {
      width: 170px;
      height: 70px;
    }
  }

  &__logoImg {
    max-width: 100%;
    max-height: 50px;
  }

  &__logoText {
    margin-left: 8px;
    font-weight: 500;
    line-height: 1.2;
    font-size: 18px;
    text-align: left;
    display: flex;
    flex-direction: column;

    &.active {
      color: white;
    }

    @include below($desktopSml) {
      font-size: 14px;
    }

    span {
      color: $additionalColor;
    }
  }

  &__btn {
    padding: 13px 25px;
    cursor: pointer;
    &--desctop {
      @include below($desktopMd) {
        display: none;
      }
    }
    &--mobile {
      margin-bottom: 28px;
    }
  }

  &__tel {
    display: block;
    font-weight: 500;
    font-size: 18px;
    white-space: nowrap;
    @include below($mobileLrg) {
      font-size: 12px;
    }
  }

  &__right, &__left {
    display: flex;
    height: 100%;
    align-items: center;
  }
  &__worktime, &__address {
    opacity: 0.7;
    font-size: 14px;
    margin-right: 40px;
    @include below($mobileLrg) {
      font-size: 12px;
      width: 70%;
      margin-right: 10px;
    }
    @include below($mobileDef) {
      width: 100%;
      font-size: 10px;
    }
  }
  &__worktime--mobile {
    opacity: 1;
    @include below($mobileLrg) {
      display: none !important;
    }
  }
  &__routeSet, &__worktime {
    display: flex;
    align-items: center;
    &--desctop {
      @include below($desktopMd) {
        display: none;
      }
    }
  }

  &__routeText {
    color: $additionalColor;
    border-bottom: 1px dashed $additionalColor;
  }

  &__icon {
    margin-right: 6px;
    width: 23px;
    height: 23px;
  }


  &__showMenu {
    width: 50px;
    height: 50px;
    padding: 15px 12px;
    background: $additionalColor;
    box-shadow: 0px 0px 8px rgba(34, 34, 34, 0.15);
    border-radius: 6px;
    z-index: 2;

    span {
      display: block;
      margin-bottom: 5px;
      background: #FFFFFF;
      border-radius: 5px;
      width: 100%;
      height: 3px;
      transition: all 0.25s ease;
      &:last-child {
        margin-bottom: 0;
      }
    }
    &.active {
      span:nth-child(1) {
        width: 120%;
        transform: rotate(45deg) translate(2px, 5px);
      }
      span:nth-child(2) {
        display: none;
      }
      span:nth-child(3) {
        width: 120%;
        transform: rotate(-45deg) translate(1px, -4px);
      }
    }
    @include above($desktopMd) {
      display: none;
    }
  }
}
.headerMobile {
  padding: 30px 17px;
  background: #2F3137;
  color: white;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @include below($mobileLrg) {
    padding-bottom: 0;
  }
  &__left {
    width: 60%;
    @include below($mobileLrg) {
      width: 100%;
      order: 1;
    }
  }
  &__right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    @include below($mobileLrg) {
      margin-bottom: 25px;
      width: 100%;
      align-items: flex-start;
    }

  }
  &__nav {
    margin-bottom: 15px;
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
  }
  &__item {
    width: 50%;
    @include below($mobileLrg) {
      width: 100%;
    }
  }
  &__link {
    display: block;
    font-weight: 500;
    font-size: 16px;
    padding: 0 15px 15px 0;
  }
  &__wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  &__worktime {
    @include above($mobileLrg) {
      display: none;
    }
  }
}
</style>
