<template>
<div class="individual">
  <img src="/img/individual.png" alt="Корпоративным клиентам" class="individual__img">
  <div class="cnt">
    <div class="individual__content">
      <h2 class="individual__title">Корпоративным клиентам</h2>
      <p class="individual__desc">Предлагаем специальные условия для корпоративных клиентов. Работа по договору. Для Вас доступны любые способы оплаты. Оставьте заявку, чтобы получить индивидуальное предложение для Вашей компании</p>
      <button class="btn individual__btn" @click="openPopup">Получить индивидуальные условия</button>
    </div>
  </div>
</div>
</template>

<script>
import {bus} from "@/components/bus";

export default {
  name: "ModelIndividual",
  methods: {
    openPopup() {
      bus.$emit('togglePopup', {
        title: 'Оставьте свои контактные данные и мы предложим удобное время для записи',
        btnText: 'Отправить'
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.individual {
  background: #515151;
  padding: 90px 0;
  position: relative;
  @include below($desktopSml) {
    padding: 35px 0;
  }
  &__content {
    position: relative;
    z-index: 1;
    max-width: 50%;
    @include below($desktopSml) {
      max-width: 100%;
    }
  }
  &__img {
    position: absolute;
    right: 30px;
    bottom: 0;
    object-fit: contain;
    max-height: 90%;
    max-width: 50%;
    @include below($desktopSml) {
      display: none;
    }
  }
  &__btn {
    padding: 13px 37px;
    @include below($tablet) {
      width: 100%;
    }
  }
  &__title {
    margin-bottom: 40px;
    font-weight: 800;
    font-size: 60px;
    line-height: 1.1;
    color: white;
    @include below($desktopSml) {
      font-size: 40px;
      margin-bottom: 20px;
    }
    @include below($tablet) {
      font-size: 28px;
    }
  }
  &__desc {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 40px;
    color: white;
    @include below($desktopSml) {
      margin-bottom: 15px;
    }
    @include below($tablet) {
      font-size: 14px;
    }
  }
}
</style>
