<template>
  <section class="modelText" id="modelText">
    <div class="cnt">

      <h1>Политика обработки и защиты персональных данных&nbsp;</h1>

      <p><br /><br>
      <strong>1. ОБЩИЕ ПОЛОЖЕНИЯ</strong></p>
      <br>
      <p>1.1. Настоящая Политика в области обработки и защиты персональных данных клиентов &laquo;Звезды Невы&raquo; (Индивидуальный предприниматель: Санкин Валерий Викторович, ОГРНИП 319565800076651, далее &ndash; Оператор), разработана с целью соблюдения прав и законных интересов субъекта персональных данных при их обработке (далее &ndash; Политика).<br />
      1.2. Настоящая Политика разработана во исполнении требований п. 2 ч. 1 ст. 18.1 Федерального закона &quot;О персональных данных&quot; от 27.07.2006 N 152-ФЗ, определяющего в качестве меры, направленного на обеспечение выполнения оператором своих обязанностей, издание оператором документов, определяющих политику оператора в отношении обработки персональных данных, локальных актов по вопросам обработки персональных данных, а также локальных актов, устанавливающих процедуры, направленные на предотвращение и выявление нарушений законодательства Российской Федерации, устранение последствий таких нарушений.<br />
      1.3. Действие настоящей Политики распространяется на все операции, совершаемые Оператором с персональными данными с использованием средств автоматизации или без их использования.<br />
      1.4. Обработка персональных данных у Оператора основана на следующих принципах:<br />
      &bull;&nbsp;&nbsp; &nbsp;законности целей и способов обработки персональных данных и добросовестности;<br />
      &bull;&nbsp;&nbsp; &nbsp;соответствия целей обработки персональных данных целям, заранее определенным и заявленным при сборе персональных данных;<br />
      &bull;&nbsp;&nbsp; &nbsp;соответствия объема и характера обрабатываемых персональных данных, способов обработки персональных данных целям обработки персональных данных;<br />
      &bull;&nbsp;&nbsp; &nbsp;достоверности персональных данных, их актуальности и достаточности для целей обработки, недопустимости обработки избыточных по отношению к целям сбора персональных данных;<br />
      &bull;&nbsp;&nbsp; &nbsp;легитимности организационных и технических мер по обеспечению безопасности персональных данных;<br />
      &bull;&nbsp;&nbsp; &nbsp;непрерывности повышения уровня знаний работников в сфере обеспечения безопасности персональных данных при их обработке;<br />
      &bull;&nbsp;&nbsp; &nbsp;стремления к постоянному совершенствованию системы защиты персональных данных.</p>
      <br><br>
      <p><strong>2. ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ</strong></p>
      <br>
      <p>Персональные данные, разрешенные субъектом персональных данных для распространения, - персональные данные, доступ неограниченного круга лиц к которым предоставлен субъектом персональных данных путем дачи согласия на обработку персональных данных, разрешенных субъектом персональных данных для распространения в порядке, предусмотренном настоящим Федеральным законом.<br />
      Обработка персональных данных - любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.<br />
      Распространение персональных данных - действия, направленные на раскрытие персональных данных неопределенному кругу лиц.<br />
      Предоставление персональных данных - действия, направленные на раскрытие персональных данных определенному лицу или определенному кругу лиц.<br />
      Блокирование персональных данных - временное прекращение обработки персональных данных (за исключением случаев, если обработка необходима для уточнения персональных данных).<br />
      Уничтожение персональных данных - действия, в результате которых становится невозможным восстановить содержание персональных данных в информационной системе персональных данных и (или) в результате которых уничтожаются материальные носители персональных данных.<br />
      Обезличивание персональных данных - действия, в результате которых становится невозможным без использования дополнительной информации определить принадлежность персональных данных конкретному субъекту персональных данных.<br />
      Информационная система персональных данных - совокупность содержащихся в базах данных персональных данных и обеспечивающих их обработку информационных технологий и технических средств.<br />
      Пользователь сайта (пользователь) &ndash; человек, посетивший данный сайт, а также пользующийся его программами и продуктами.<br />
      Cookies &mdash; короткий фрагмент данных, пересылаемый веб-браузером или веб-клиентом веб-серверу в HTTP-запросе, всякий раз, когда Пользователь пытается открыть страницу Интернет-магазина. Фрагмент хранится на компьютере Пользователя.<br />
      IP-адрес &mdash; уникальный сетевой адрес узла в компьютерной сети, построенной по протоколу TCP/IP.</p>
      <br><br>
      <p><strong>3. ПРЕДМЕТ И ЦЕЛИ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ</strong></p>
      <br>
      <p>3.1. Политика применяется к обработке персональных данных Оператором в том числе в следующих случаях:<br />
      &bull;&nbsp;&nbsp; &nbsp;использование веб-сайтов и мобильных приложений, владельцем которых является Оператор;<br />
      &bull;&nbsp;&nbsp; &nbsp;любые обращения к Оператору в любой форме, направление жалоб, комментариев или замечаний и предложений;&nbsp;<br />
      &bull;&nbsp;&nbsp; &nbsp;посещение офисов и иных помещений Оператора;&nbsp;<br />
      &bull;&nbsp;&nbsp; &nbsp;продажа автомобилей и иных товаров, проведение работ (включая сервисное обслуживание автомобилей), оказание услуг (включая информационные услуги с использованием веб-сервисов);<br />
      &bull;&nbsp;&nbsp; &nbsp;в иных случаях.<br />
      3.2. Субъектами, чьи персональные данные обрабатываются в соответствии с Политикой, являются:<br />
      &bull;&nbsp;&nbsp; &nbsp;клиенты (потенциальные клиенты) Оператора и их представители;<br />
      &bull;&nbsp;&nbsp; &nbsp;контрагенты или бизнес-партнеры (потенциальные контрагенты или бизнес-партнеры) Оператора и их представители;&nbsp;<br />
      &bull;&nbsp;&nbsp; &nbsp;иные лица в случаях, предусмотренных пунктом 3.1 выше.<br />
      3.3 К персональным данным, обрабатываемым Оператором, относится следующая информация о субъекте персональных данных:<br />
      1)&nbsp;&nbsp; &nbsp;фамилия, имя, отчество;<br />
      2)&nbsp;&nbsp; &nbsp;пол;<br />
      3)&nbsp;&nbsp; &nbsp;дата, месяц и год рождения;<br />
      4)&nbsp;&nbsp; &nbsp;город;<br />
      5)&nbsp;&nbsp; &nbsp;адрес фактического проживания;<br />
      6)&nbsp;&nbsp; &nbsp;финансовая информация;<br />
      7)&nbsp;&nbsp; &nbsp;номер мобильного телефона;<br />
      8)&nbsp;&nbsp; &nbsp;адрес электронной почты;<br />
      9)&nbsp;&nbsp; &nbsp;данные автомобиля;<br />
      10)&nbsp;&nbsp; &nbsp;IP-адрес;<br />
      11)&nbsp;&nbsp; &nbsp;информация из cookie;<br />
      12)&nbsp;&nbsp; &nbsp;информация о браузере пользователя;<br />
      13)&nbsp;&nbsp; &nbsp;время посещения сайта;<br />
      14)&nbsp;&nbsp; &nbsp;адрес запрашиваемой страницы сайта;<br />
      15)&nbsp;&nbsp; &nbsp;адрес страницы, на которой располагается рекламный блок;<br />
      16)&nbsp;&nbsp; &nbsp;реферер (адрес предыдущей страницы).<br />
      17)&nbsp;&nbsp; &nbsp;иные данные и информация, представленные субъектом и или ставшие известными Оператором (приведенный перечень может сокращаться или расширяться в зависимости от конкретного случая и целей обработки).<br />
      3.4. Обработка персональных данных у Оператора осуществляется с целью:<br />
      &bull;&nbsp;&nbsp; &nbsp;использования для автоматизации процессов формирования первичных документов (договоров, счетов на оплату, универсальных передаточных документов, различных Актов, паспортов транспортных средств, заказ-нарядов, предварительных наряд-заказов, накладных и т.п.);<br />
      &bull;&nbsp;&nbsp; &nbsp;идентификации клиента при его обращении к Оператору по телефону и/или электронным средствам связи;<br />
      &bull;&nbsp;&nbsp; &nbsp;идентификации клиента при осуществлении основных видов деятельности Оператора;<br />
      &bull;&nbsp;&nbsp; &nbsp;использования для автоматизации процессов документарного оформления бизнес&ndash;процессов при продаже автомобилей, оказании услуг (выполнения работ) по ремонту и техническому обслуживанию автомобилей и формирования отчетов, при продаже запчастей, аксессуаров;<br />
      &bull;&nbsp;&nbsp; &nbsp;формирования единой клиентской базы покупателей автомобилей для автоматизации задач послепродажного обслуживания и маркетинга;<br />
      &bull;&nbsp;&nbsp; &nbsp;обеспечения рекламной деятельности Оператора;<br />
      &bull;&nbsp;&nbsp; &nbsp;использования для автоматизации процессов формирования отчетных документов по деятельности страховых агентов;<br />
      &bull;&nbsp;&nbsp; &nbsp;автоматизации, оптимизации операционной деятельности Оператора;<br />
      &bull;&nbsp;&nbsp; &nbsp;ведения и актуализации клиентской базы;<br />
      &bull;&nbsp;&nbsp; &nbsp;получения и исследования статистических данных об объемах продаж и качестве оказываемых услуг;<br />
      &bull;&nbsp;&nbsp; &nbsp;проведения маркетинговых программ;<br />
      &bull;&nbsp;&nbsp; &nbsp;изучения конъюнктуры рынка автомобилей, автомобильных запасных частей и аксессуаров, услуг по ремонту автомобилей;<br />
      &bull;&nbsp;&nbsp; &nbsp;проведению опросов и исследований, направленных на выявление удовлетворенности/неудовлетворенности клиентов, постоянного совершенствования уровня предоставляемых услуг;<br />
      &bull;&nbsp;&nbsp; &nbsp;реализации страховых продуктов, в том числе, но не ограничиваясь, оформление полисов КАСКО, ОСАГО и т.д.;<br />
      &bull;&nbsp;&nbsp; &nbsp;технической поддержки при обработке информации, документации и персональных данных с использованием средств автоматизации и без такого использования.</p>
      <br><br>
      <p><strong>4. ПОРЯДОК ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ</strong></p>
      <br>
      <p>4.1. Обработка персональных данных должна осуществляться с соблюдением принципов и правил, предусмотренных Федеральным законом &quot;О персональных данных&quot; от 27.07.2006 N 152-ФЗ и Политикой.<br />
      4.2. Обработка персональных данных Оператором осуществляется путем любых действий (операций), допустимых законодательством Российской Федерации.<br />
      Обработка осуществляется Оператором, а также иными третьими лицами, которые были привлечены Оператором к обработке, или которым были переданы персональные данные и иная информация (или предоставлен доступ к ним) в соответствии с законодательством Российской Федерации.&nbsp;<br />
      Передача персональных данных работника Оператора третьим лицам, осуществляется только с письменного согласия субъекта, за исключением случаев, предусмотренных законодательством.<br />
      В случае необходимости передачи Оператором персональных данных третьим лицам, она осуществляется только после подписания между Оператором и третьей стороной соглашения о неразглашении конфиденциальной информации, за исключением случаев, предусмотренных законодательством.<br />
      4.3. Обработка персональных данных осуществляется с согласия субъекта персональных данных на обработку его персональных данных, за исключением случаев, установленных законодательством Российской Федерации, когда обработка персональных данных может осуществляться без такого согласия.<br />
      4.5. Согласие может быть отозвано путем письменного уведомления, направленного в адрес Оператора заказным почтовым отправлением.<br />
      4.6. Условием прекращения обработки персональных данных может являться достижение целей обработки персональных данных, истечение срока действия Согласия или отзыв Согласия субъектом персональных данных, а также выявление неправомерной обработки персональных данных.<br />
      4.7. Обработка специальных категорий персональных данных, касающихся расовой, национальной принадлежности, политических взглядов, религиозных или философских убеждений, состояния здоровья, интимной жизни, не осуществляется.<br />
      4.8. Обработка биометрических данных (сведения, которые характеризуют физиологические и биологические особенности человека, на основании которых можно установить его личность и которые используются оператором для установления личности субъекта персональных данных) не осуществляется. В соответствующих случаях, при предъявлении субъектами персональных данных паспортов или иных документов, содержащих фотографию субъекта, Оператор не использует данную фотографию для установления личности субъекта персональных данных (идентификация), а использует для удостоверения тождественности лица, предъявившего документ, с лицом, изображенным на фотографии в этом документе (аутентификация).<br />
      4.9. Оператор не размещает персональные данные субъектов персональных данных в общедоступных источниках без его согласия.&nbsp;<br />
      4.11. Запрещается принятие на основании исключительно автоматизированной обработки персональных данных решений, порождающих юридические последствия в отношении субъекта персональных данных или иным образом затрагивающих его права и законные интересы.<br />
      4.12. Оператор при обработке персональных данных обязан принимать необходимые правовые, организационные и технические меры или обеспечивать их принятие для защиты персональных данных от неправомерного или случайного доступа к ним, уничтожения, изменения, блокирования, копирования, предоставления, распространения персональных данных, а также от иных неправомерных действий в отношении персональных данных.<br />
      4.13. Предоставление персональных данных органам государственной власти и местного самоуправления, в суды, правоохранительные органы, а также иным надзорным органам осуществляется Оператором в случаях и в порядке, предусмотренных законодательством РФ.</p>
      <br><br>
      <p><strong>5. ДОСТУП К ПЕРСОНАЛЬНЫМ ДАННЫМ</strong></p>
      <br>
      <p>5.1. Доступ к персональным данным имеют работники Оператора, которым персональные данные необходимы в связи с исполнением ими должностных обязанностей.<br />
      5.2. Работники Оператора, получившие доступ к персональным данным, принимают обязательства по обеспечению конфиденциальности обрабатываемых персональных данных.<br />
      5.3. Субъект персональных данных имеет право:<br />
      &bull;&nbsp;&nbsp; &nbsp;на получение информации, касающейся обработки его персональных данных, в том числе содержащей:<br />
      - подтверждение факта обработки персональных данных;<br />
      - правовые основания и цели обработки персональных данных;<br />
      - цели и применяемые способы обработки персональных данных;<br />
      - сведения о лицах, которые имеют доступ к персональным данным или которым могут быть раскрыты персональные данные на основании договора или на основании законодательства Российской Федерации;<br />
      - обрабатываемые персональные данные, относящиеся к соответствующему субъекту персональных данных, источник их получения, если иной порядок представления таких данных не предусмотрен законодательством Российской Федерации;<br />
      - сроки обработки персональных данных, в том числе сроки их хранения;<br />
      - порядок осуществления субъектом персональных данных прав, предусмотренных законодательством Российской Федерации;<br />
      - наименование или фамилию, имя, отчество и адрес лица, осуществляющего обработку персональных данных по поручению Оператора, если обработка поручена или будет поручена такому лицу;<br />
      - иные сведения, предусмотренные законодательством Российской Федерации;<br />
      &bull;&nbsp;&nbsp; &nbsp;требовать исправления неверных, неточных, устаревших персональных данных;<br />
      &bull;&nbsp;&nbsp; &nbsp;отозвать согласие на обработку персональных данных и иные права в соответствии с действующим российским законодательством.<br />
      5.4. Субъекты персональных данных несут ответственность за предоставление достоверных сведений, а также за своевременное обновление предоставленных данных в случае каких-либо изменений.<br />
      5.5. Оператор предпринимает разумные меры для поддержания точности и актуальности имеющихся персональных данных, а также удаления персональных данных в случаях, если они являются устаревшими, недостоверными или излишними, либо если достигнуты цели их обработки.</p>
      <br>
      <p><br />
      <strong>6. СБОР ПЕРСОНАЛЬНЫХ ДАННЫХ С ИСПОЛЬЗОВАНИЕМ САЙТА&nbsp;</strong></p>
      <br>
      <p>6.1. При сборе персональных данных субъектов посредством информационно-телекоммуникационной сети Интернет Оператор обеспечивает запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение персональных данных субъектов с использованием баз данных, находящихся на территории Российской Федерации.<br />
      6.2. Получение согласия субъекта на сбор и обработку персональных данных осуществляется в случае предоставления субъектом любых личных данных с использованием электронной формы на сайте Оператора.<br />
      6.3. Сайт Оператора использует файлы &laquo;cookie&raquo; и собирает сведения, которые необходимы Оператора в целях анализа эффективности и улучшения работы сервисов сайта.<br />
      6.4. При посещении сайтов Оператор информирует пользователей о сборе и использовании файлов &laquo;cookie&raquo;.<br />
      6.5. Защита данных, автоматически передаваемых при просмотре рекламных блоков и посещении страниц с установленными на них статистическими скриптами системы (пикселями) осуществляется сайтом.<br />
      6.6. Последствием отключения cookies может стать невозможность доступа к некоторым разделам сайта.<br />
      6.7. Сайт собирает статистику об IP-адресах всех посетителей. Данные сведения нужны, чтобы выявить и решить технические проблемы и проконтролировать, насколько законным будет проведение финансовых платежей.<br />
      6.8. Администрация сайта обязана не разглашать персональные данные, сообщаемые Пользователями, регистрирующимися на сайте или оформляющими заказ на покупку товара, а также обеспечивать этим данным абсолютную конфиденциальность.</p>
      <br><br>
      <p><strong>7. ХРАНЕНИЕ ПЕРСОНАЛЬНЫХ ДАННЫХ</strong></p>
      <br>
      <p>7.1. Персональные данные Клиентов на бумажных носителях хранятся в сейфах.<br />
      7.2. Персональные данные Клиентов в электронном виде хранятся в локальной компьютерной сети Оператора, в электронных папках и файлах на сервере Оператора.<br />
      7.3. Документы, содержащие персональные данные Клиентов, хранятся в запирающихся шкафах (сейфах), обеспечивающих защиту от несанкционированного доступа. В конце рабочего дня все документы, содержащие персональные данные Клиентов, помещаются в шкафы (сейфы), обеспечивающие защиту от несанкционированного доступа.<br />
      7.4. Защита доступа к электронным базам данных, содержащим персональные данные Клиентов, обеспечивается:<br />
      &bull;&nbsp;&nbsp; &nbsp;использованием лицензированных антивирусных и антихакерских программ, не допускающих несанкционированный вход в локальную сеть Оператора;<br />
      &bull;&nbsp;&nbsp; &nbsp;разграничением прав доступа с использованием учетной записи;<br />
      &bull;&nbsp;&nbsp; &nbsp;системой паролей: на уровне локальной компьютерной сети. Пароли устанавливаются Системным администратором Оператора и сообщаются индивидуально сотрудникам, имеющим доступ к персональным данным Клиентов.<br />
      7.5. Несанкционированный вход в ПК, в которых содержатся персональные данные Клиентов, блокируется паролем, который устанавливается Системным администратором и не подлежит разглашению.<br />
      7.6. Все электронные папки и файлы, содержащие персональные данные Клиентов, защищаются паролем, который устанавливается непосредственным пользователем компьютера и в обязательном порядке изменяется каждые 90 (Девяносто) дней, при это нельзя использовать последние введенные 5(Пять) паролей.</p>
      <br><br>
      <p><strong>8. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</strong></p>
      <br>
      <p>8.1. Все отношения, касающиеся обработки персональных данных, не получившие отражение в настоящей Политике, регулируются законодательством Российском Федерации.<br />
      8.2. Оператор имеет право вносить изменения в настоящую Политику. При внесении изменений в актуальной редакции указывается дата последнего обновления. Любые изменения подлежат опубликованию.<br />
      8.3. В случае, если какое-либо из положений настоящей Политики является или становится недействительным, это не затрагивает действительность иных положений.&nbsp;<br />
      8.4. При изменении нормативно-правовых актов, использованных при составлении Политики, настоящая Политика продолжает действовать в части, не противоречащей действующему законодательству. В остальной части Оператор руководствуется нормами действующего законодательства РФ.<br />
      8.5. Правовыми основаниями обработки персональных данных Оператором являются:<br />
      Конституция РФ;<br />
      Гражданский кодекс РФ;<br />
      Федеральный закон от 27 июля 2006 г. N 149-ФЗ &quot;Об информации, информационных технологиях и о защите информации&quot;;<br />
      Закон РФ от 27 декабря 1991 г. N 2124-1 &quot;О средствах массовой информации&quot;;<br />
      Федеральный закон от 26 декабря 2008 г. N 294-ФЗ &quot;О защите прав юридических лиц и индивидуальных предпринимателей при осуществлении государственного контроля (надзора) и муниципального контроля&quot;;<br />
      Указ Президента РФ от 6 марта 1997 г. N 188 &quot;Об утверждении перечня сведений конфиденциального характера&quot;;<br />
      Постановление Правительства Российской Федерации от 06 июля 2008 г. N 512 &quot;Об утверждении требований к материальным носителям биометрических персональных данных и технологиям хранения таких данных вне информационных систем персональных данных&quot;;<br />
      Постановление Правительства Российской Федерации от 15 сентября 2008 г. N 687 &quot;Об утверждении Положения об особенностях обработки персональных данных, осуществляемой без использования средств автоматизации&quot;;<br />
      Постановление Правительства Российской Федерации от 1 ноября 2012 г. N 1119 &quot;Об утверждении требований к защите персональных данных при их обработке в информационных системах персональных данных&quot;;<br />
      Приказ Роскомнадзора от 5 сентября 2013 г. N 996 &quot;Об утверждении требований и методов по обезличиванию персональных данных&quot;;<br />
      Приказ ФСТЭК России от 18 февраля 2013 г. N 21 &quot;Об утверждении состава и содержания организационных и технических мер по обеспечению безопасности персональных данных при их обработке в информационных системах персональных данных&quot;;<br />
      Приказ Роскомнадзора от 24.02.2021 N 18 &quot;Об утверждении требований к содержанию согласия на обработку персональных данных, разрешенных субъектом персональных данных для распространения&quot;;&nbsp;<br />
      уставные документы Оператора;<br />
      договоры, заключаемые между Оператором и субъектами персональных данных;<br />
      согласия субъектов персональных данных на обработку персональных данных;<br />
      иные основания, когда согласие на обработку персональных данных не требуется в силу закона.</p>
    </div>

  </section>

</template>

<script>
export default {
  name: "ModelText"
}
</script>

<style lang="scss" scoped>
.modelText{
  position: relative;
  padding: 50px 0 50px;
  &__title {

  }
}
.modelText h1{
  font-size: 35px;
}
</style>