<template>
<section class="modelService" id="modelService" v-if="Array.isArray(items) && items.length">
  <div class="cnt">
    <div class="modelService__wrap">
      <div class="modelService__text">
        <h2 class="modelService__title title-sect">Услуги
          сервиса</h2>
        <p class="modelService__descr descr-sect">Предлагаем вам ознакомиться с видами работ, которые мы оказываем в «Звезде Невы».</p>
        <FlyingObj :hidden-xl="true" top="-85px" right="-10px" name="flying-object-4"/>
      </div>
      <div class="modelService__content">
        <div class="modelService__item" v-for="item in items" @click="openPopup">
          <div class="modelService__edgeLeftTop"></div>
          <div class="modelService__edgeRightBottom"></div>
          <div class="modelService__itemText">
            <div class="modelService__i-title">{{item.title}}</div>
            <ul class="modelService__i-text">
              <li v-for="line in item.text">
                {{line}}
              </li>
            </ul>
          </div>


          <img :src="item.img" :alt="item.title" class="modelService__itemImg">
        </div>
      </div>

    </div>
  </div>
  <FlyingObj :hidden1440="true" :hidden-xl="true" bottom="-75px" right="60px" name="flying-object-5"/>
</section>
</template>

<script>
import FlyingObj from "../Object/FlyingObj";
import { bus } from "../bus";

export default {
  name: "ModelService",
  props: {
    items: {
      type: Array
    }
  },
  components: {
    FlyingObj
  },
  methods: {
    openPopup() {
      bus.$emit('togglePopup', {
        title: 'Запишитесь на сервисное обслуживание',
        btnText: 'Записаться'
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.modelService {
  position: relative;
  padding: 50px 0;
  background: #515151;
  @include below($desktopSml) {
    padding: 50px 0;
  }
  &__content {
    width: calc(100% * (2/3));
    display: flex;
    flex-wrap: wrap;
    @include below($desktopSml) {
      width: 60%;
    }
    @include below($mobileLrg) {
      width: 100%;
      margin-top: 15px;
    }
  }
  &__edgeLeftTop {
    position: absolute;
    top: 8px;
    left: 8px;
    border-top: 2.4px solid #ef850d;
    border-left: 2.4px solid #ef850d;
    border-top-left-radius: 4px;
    width: 22px;
    height: 20px;
  }
  &__edgeRightBottom {
    position: absolute;
    bottom: 8px;
    right: 8px;
    border-bottom: 2.4px solid #ef850d;
    border-right: 2.4px solid #ef850d;
    border-bottom-right-radius: 4px;
    width: 22px;
    height: 20px;
  }
  &__item {
    flex: 0 1 calc(50% - 10px);
    height: 310px;
    padding: 22px;
    background: #33353C;
    box-shadow: 0px 0px 9px rgba(11, 46, 98, 0.3);
    border-radius: 6px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    margin-right: 10px;
    margin-bottom: 20px;
    cursor: pointer;
    &:nth-child(2n) {
      margin-right: 0px;
    }
    @include below($desktopMd){
        height: 330px;
      }
    @include below($desktopSml) {
      flex: 100%;
      margin-bottom: 10px;
      margin-right: 0;
      margin-left: 0;
      height: 320px;
    }
    @include below($mobileDef){
        height: 350px;
      }
  }
  &__i-title {
      font-weight: bold;
      font-size: 20px;
      @include below($desktopMd){
        font-size: 18px;
      }
      @include below($desktopSml) {
        font-size: 14px;
      }
      @include below($mobileLrg) {
        font-size: 20px;
      }
    }
  &__i-text {
      font-size: 16px;
      font-weight: 100;
      @include below($desktopMd){
        font-size: 14px;
      }
      @include between($mobileLrg, $desktopSml){
        font-size: 12px;
      }
    }
    &__i-text li{
      margin: 5px 0;
      &:before{
         content: "• ";
      }
    }
  &__itemText {
    position: relative;
    z-index: 1;
    color: #FFFFFF;
  }
  &__itemTitle {
    font-weight: bold;
    font-size: 20px;
    color: #FFFFFF;
    @include below($tablet) {
      font-size: 16px;
    }
  }
  &__itemImg {
    position: absolute;
    bottom: 0;
    right: 0;
    max-height: 60%;
  }
  &__text {
    width: calc(100% / 3);
    margin-right: 30px;
    position: relative;
    @include below($mobileLrg) {
      width: 100%;
      margin-right: 0;
    }
  }
  &__title {
    line-height: 90%;
    letter-spacing: 0.01em;
    color: #FFFFFF;
  }
  &__descr {
    color: #FFFFFF;
  }
  &__wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include below($mobileLrg) {
      flex-wrap: wrap;
    }
  }
}
</style>
