<template>
<datepicker v-model="curVal"
  :disabledDates="{
    to: new Date()
  }"
  :placeholder="placeholder"
            class="customDatepicker"
            :language="ru"
/>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import {ru} from 'vuejs-datepicker/dist/locale'
export default {
  name: "CustomDatepicker",
  components: {
    Datepicker
  },
  data() {
    return {
      ru: ru
    }
  },
  props: {
    value: {
      required: true
    },
    placeholder: {
      type: String,
      default: ''
    }
  },
  computed: {
    curVal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style lang="scss">
.customDatepicker {
  input {
    width: 100%;
    height: 50px;
    background: #FFFFFF;
    border: 1px solid #D8D8D8;
    box-shadow: 0px 0px 9px rgba(34, 34, 34, 0.12);
    border-radius: 6px;
    padding: 15px 40px 15px 15px;
    &.error {
      border: 1px solid #ea0000;
      animation: shaking 0.4s ease;
    }
    &::placeholder {
      font-size: 16px;
      color: #9A9A9A;
    }
  }
}
</style>