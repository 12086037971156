<template>
  <div class="customInput">
    <textarea
           class="input"
           :class="{error: error}"
           :placeholder="placeholder"
           :value="name"
           rows="3"
           @input="updateSelf($event.target.value)" />
  </div>
</template>

<script>
import SvgIcon from "../SvgIcon";
import {mask} from 'vue-the-mask'

export default {
  name: "CustomTextarea",
  model: {
    prop: "name",
    event: "input"
  },
  props: {
    name,
    error: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default() {
        return ''
      }
    },
    icons: {
      type: String,
      default() {
        return "";
      }
    },
    mask: {
      type: String,
      default() {
        return ''
      }
    }
  },
  methods: {
    updateSelf(name) {
      if (name !== '')
        this.$emit("removeError", name);
      this.$emit("input", name);
    }
  },
  components: {
    SvgIcon
  },
  directives: {mask}
}
</script>

<style lang="scss" scoped>
.customInput {
  width: 100%;
  position: relative;
}
.input {
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #D8D8D8;
  box-shadow: 0px 0px 9px rgba(34, 34, 34, 0.12);
  border-radius: 6px;
  padding: 15px 40px 15px 15px;
  resize: none;
  &.error {
    border: 1px solid #ea0000;
    animation: shaking 0.4s ease;
  }
  &::placeholder {
    font-size: 16px;
    color: #9A9A9A;
  }
}
.icons {
  position: absolute;
  right: 20px;
  top: calc(50% - 9px);
  height: 18px;
  width: 18px;
  cursor: pointer;
}
@keyframes shaking {
  0% {
    transform: translateX(-3px);
  }
  50% {
    transform: translateX(3px);
  }
  100% {
    transform: translateX(0);
  }
}
</style>