<template>
  <div class="promoSlider__wrap">
    <swiper v-if="Array.isArray(slideList) && slideList.length && !isMobile"
            class="promoSlider"
            :options="swiperOption"
    >
      <swiper-slide
          v-for="(slide, i) in slideList"
          :key="i"
          class="promoSlider__slide"
        >
        <div @click="openPopup" :style="slide.bg ? `background: url(${slide.bg}) center center/cover no-repeat` : ''">
          <div class="promoSlider__top" v-if=" slide.additionValueHTML || slide.titleHTML || slide.subtitleHTML ">
            <div class="promoSlider__additionValue" v-if="slide.additionValueHTML" v-html=" slide.additionValueHTML ">

            </div>
            <div class="promoSlider__title" v-if="slide.titleHTML" v-html=" slide.titleHTML " >
            </div>
            <div class="promoSlider__subtitle" v-if="slide.subtitleHTML" v-html=" slide.subtitleHTML " >
            </div>
          </div>
          <div class="promoSlider__btn">
            Подробнее
          </div>
          <img :src="slide.img" class="promoSlider__img" :class="{btm: slide.imgBottom}">
        </div>
      </swiper-slide>
    </swiper>
    <div  v-else
          v-for="(slide, i) in slideList"
          :key="i"
          class="promoSlider__slide"
          >
      <div @click="openPopup" :style="slide.bg ? `background: url(${slide.bg}) center center/cover no-repeat` : ''">
        <div class="promoSlider__top" v-if=" slide.additionValueHTML || slide.titleHTML || slide.subtitleHTML ">
          <div class="promoSlider__additionValue" v-if="slide.additionValueHTML" v-html=" slide.additionValueHTML ">

          </div>
          <div class="promoSlider__title" v-if="slide.titleHTML" v-html=" slide.titleHTML " >
          </div>
          <div class="promoSlider__subtitle" v-if="slide.subtitleHTML" v-html=" slide.subtitleHTML " >
          </div>
        </div>
        <div class="promoSlider__btn">
          Подробнее
        </div>
        <img :src="slide.img" class="promoSlider__img"  :class="{btm: slide.imgBottom}">
      </div>
    </div>
  </div>

</template>

<script>
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import "swiper/css/swiper.css"
import { bus } from "../bus";
import SvgIcon from "../SvgIcon";


export default {
  name: "PromoSlider",
  props: {
    slideList: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      swiperOption: {
        slidesPerView: 2,
        spaceBetween: 20,
        navigation: {
          nextEl: '.sliderBtnNext',
          prevEl: '.sliderBtnPrev',
        },
        pagination: true,
        isMobile: false,
        loop: false
      }
    }
  },
  created() {
    this.isMobile = window.innerWidth < 568
  },
  components: {
    Swiper,
    SwiperSlide,
    SvgIcon
  },
  directives: {
    swiper: directive
  },
  methods: {
    openPopup() {
      bus.$emit('togglePopup', {
        title: 'Получить больше информации',
        btnText: 'Отправить'
      });
    }
  }
}
</script>

<style lang="scss">
.promoSlider {
  @include below($tablet) {
    margin-top: 35px;
  }
  &__wrap {
    min-width: calc(100% / 3);
    @include below(568) {
      margin-top: 15px;
    }
  }
  &__slide {
    position: relative;
    height: 250px;
    background: #33353C;
    box-shadow: 0px 0px 9px rgba(11, 46, 98, 0.3);
    border-radius: 6px;
    overflow: hidden;
    cursor: pointer;
    @include below(568) {
      height: 138px;
      margin-bottom: 10px;
      overflow: hidden;
    }
    > div {
      padding: 22px;
      height: 100%;
      @include below($desktopSml) {
        padding: 13px;
      }
      &:after {
        content: '';
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
      }
    }
  }
  &__top {
    position: relative;
    z-index: 1;
    display: flex;
    flex-wrap: wrap;
    @include below($desktopSml) {
      flex-wrap: nowrap;
      align-items: center;
    }
  }
  &__title {
    font-weight: bold;
    font-size: 20px;
    line-height: 115%;
    color: #FFFFFF;
    max-width: 65%;
    @include below($desktopSml) {
      font-size: 16px;
      max-width: 70%;
    }
    span {
      color: $additionalDarkColor;
    }
  }
  &__img {
    position: absolute;
    right: 0;
    top: 10%;
    &.btm {
      bottom: 0;
      top: unset;
    }
    @include below($desktopSml) {
      width: 70%;
      bottom: 0;
      top: unset;
    }
    @include below(568) {
      width: 40%;
    }
  }
  &__additionValue {
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 60px;
    line-height: 0.8;
    letter-spacing: 0.02em;
    color: #FFE66A;
    margin-right: 5px;
    span {
      font-weight: 900;
      font-size: 20px;
    }
    @include below($desktopSml) {
      font-size: 40px;
      align-items: flex-end;
      span {
        font-size: 18px;
      }
    }
  }
  &__nav {
    margin-top: 25px;
    display: flex;
    @include below($tablet) {
      display: none;
    }
  }
  &__btn {
    display: inline;
    position: absolute;
    bottom: 22px;
    left: 22px;
    font-size: 18px;
    line-height: 115%;
    text-decoration-line: none;
    color: #D7D7D7;
    z-index: 2;
    &:before {
      position: absolute;
      width: 100%;
      content: '';
      bottom: 0;
      left: 0;
      height: 1px;
      background-color: #D7D7D7;
    }
    @include below($desktopSml) {
      font-size: 14px;
    }
  }
  &__subtitle {
    font-weight: bold;
    font-size: 60px;
    letter-spacing: 0.02em;
    color: #666666;
    display: flex;
    align-items: center;
    width: 100%;
    span {
      font-weight: 900;
      font-size: 20px;
    }
  }
  &__btnPrev {
    margin-right: 20px;
  }
}
</style>
