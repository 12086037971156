<template>
  <transition name="fade">
    <div class="popup" v-if="isActive">
      <div class="popup__modal">
        <div @click="closePopup" class="popup__close">
          <SvgIcon  class="popup__close" name="close"/>
        </div>
        <div class="popup__content">
          <div class="popup__title">
            {{ text }}
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import SvgIcon from "../SvgIcon";
import { bus } from "../bus"

export default {
  name: "ContactPopup",
  data() {
    return {
      isActive: false,
      text: 'Заявка успешно отправлена'
    }
  },
  components: {
    SvgIcon
  },
  mounted() {
    bus.$on('toggleSuccessPopup', (text = 'Заявка успешно отправлена') => {
      this.text = text
      this.openPopup()
    });
  },
  methods: {
    openPopup() {
      this.isActive = true;
      document.addEventListener('click', (e) => {
        if(e.target.classList.contains('popup')) {
          this.isActive = false;
        }
      })
    },
    closePopup() {
      this.isActive = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.popup {
  position: fixed;
  z-index: 100;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
  }
  &__modal {
    position: relative;
    width: 500px;
    background: #FFFFFF;
    border-radius: 18px;
    padding: 80px 40px;
    z-index: 101;
  }
  &__content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &__help {
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    color: #93949B;
    margin-bottom: 25px;
  }
  &__politic {
    font-weight: 500;
    font-size: 12px;
    text-align: center;
    color: #93949B;
    margin-bottom: 25px;
  }
  &__politic a {
    text-decoration: underline;
  }
  &__politic a:hover {
    text-decoration: none;
  }
  &__formItem {
    margin-bottom: 17px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__close {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }
  &__title {
    font-weight: 900;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #2F2F50;
    margin-bottom: 10px;
  }
  &__btn {
    background: #FBFBFB;
    border: 1.5px solid #DFDFDF;
    border-radius: 8px;
    font-weight: bold;
    font-size: 16px;
    width: 100%;
    padding: 14px 0;
    text-align: center;
    color: #3B3D52;
    transition: background-color 0.25s ease;
    &:hover {
      background: #f5f5f5;
    }
  }
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .25s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
</style>