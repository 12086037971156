<template>
  <section class="promo" id="promo">
    <div class="cnt">
      <PromoTop
          :promoTitle="promoTitle"
          :promoDescr="promoDescr"
          :promoList="promoList"
      />
      <div class="promo__content">
        <div class="promo__item">
          <span class="promo__title title-sect">Акции <span>и спец. предложения</span></span>
          <div class="promoSlider__nav">
            <div class="sliderBtnPrev promoSlider__btnPrev">
              <SvgIcon name="arrow"/>
            </div>
            <div class="sliderBtnNext promoSlider__btnNext">
              <SvgIcon name="arrow"/>
            </div>
          </div>
        </div>
        <PromoSlider :slideList="slideList"/>
      </div>
    </div>
  </section>
</template>

<script>
import SvgIcon from "../SvgIcon";
import PromoTop from './PromoTop';
import PromoSlider from '../Slider/PromoSlider'

export default {
  name: "ModelPromo",
  components: {
    PromoTop,
    PromoSlider,
    SvgIcon
  },
  data() {
    return {
      promoTitle: 'Быстрая запись на тех. обслуживание',
      promoDescr: 'Вам не нужно ждать в живой очереди салона сервиса – свяжитесь с нами по телефону и мы вам поможем',
      promoList: [
        {
          title: 'Запись на сервис',
          img: 'img/promo/promo-top1.png'
        },
        {
          title: 'Консультация',
          subtitle: '• по телефону',
          img: 'img/promo/promo-top2.png'
        }
      ],
      slideList: [
        {
          titleHTML: 'Замена масла от 3&nbsp;950₽ с фильтром. Диагностика ходовой части в Подарок! <span style="font-size: 12px; width: 70%; display: block">*Действует только до 31.01.2023</span>',
          img: 'img/promo/maslo.png',
          imgBottom: true
        },
        {
          titleHTML: 'Диагностика тормозной системы и ходовой части на стенде MAHA <span>бесплатно</span>',
          img: 'img/promo/promo-3.png',
        },{
          titleHTML: 'Хранение шин за 2&nbsp;700 Р*<span style="font-size: 12px; width: 70%; display: block">*при проведении шиномонтажа</span>',
          img: 'img/promo/shin.png',
        },{
          titleHTML: 'Антидождь в подарок* <span style="font-size: 12px; width: 70%; display: block">*при сумме обслуживания от 5 000 Р</span>',
          bg: 'img/promo/anti-bg.jpg'
        },{
          titleHTML: 'Гидрофобное покрытие кузова за 990 Р',
          bg: 'img/promo/arti-bg.jpg'
        }
      ]
    }
  }
}
</script>

<style lang="scss">
.promo {
  min-height: 435px;
  background: #515151;
  padding-bottom: 40px;
  &__content {
    padding-top: 150px;
    display: flex;
    align-items: center;
    @include below($tablet) {
      padding-top: 200px;
      display: block;
    }
  }
  &__item {
    min-width: calc(100% / 3);
    @include below($desktopSml) {
      min-width: 25%;
    }
  }
  &__title {
    position: relative;
    line-height: 90%;
    color: #FFFFFF;
    span {
      position: absolute;
      top: 10px;
      left: calc(100% - 38px);
      font-weight: 500;
      font-size: 16px;
      line-height: 115%;
      color: #FFFFFF;
      background: #ef850d;
      box-shadow: 2px 3px 8px rgba(34, 34, 34, 0.3);
      border-radius: 4px;
      padding: 2px 5px;
      white-space: nowrap;
      @include below($desktopDef) {
        left: calc(100% - 75px);
      }
      @include below($desktopSml) {
        position: static;
      }
      @include below($tablet) {
        position: absolute;
        top: -2px;
        left: calc(100% - 10px);
      }
    }
  }
  .cnt {
    position: relative;
  }
}
</style>
