var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('img',{staticClass:"flyingObj",class:{
       'hidden-xl': _vm.hiddenXl,
       'hidden-1200': _vm.hidden1200,
       'hidden-1440': _vm.hidden1440
     },style:({
        'z-index': _vm.zIndex,
        'top': _vm.top,
        'bottom': _vm.bottom,
        'left': _vm.left,
        'right': _vm.right,
        }),attrs:{"src":("/img/object/" + _vm.name + ".png"),"alt":_vm.name}})}
var staticRenderFns = []

export { render, staticRenderFns }