<template>
<div class="footer-top cnt" v-if="isHidden">
  <div class="footer-top__content">
    <div class="footer-top__logoWrap">
      <router-link to="/" class="footer-top__logo">
        <img src="@/assets/images/logo.png" alt="logo" class="footer-top__logoImg">
        <div class="footer-top__logoText">
          Звезда&nbsp;Невы <span>Сервис</span>
        </div>
      </router-link>
      <span>2022 «Звезда Невы»</span>
    </div>

    <FooterNav :list="navList" v-if="!hideLink"/>
    <div class="footer-top__contact">
      <div class="footer-top__contact-item">
        <span>Адрес:</span>
        Витебский проспект, 17к7, г. Санкт-Петербург
      </div>
      <a href="tel:+78124456060" class="footer-top__contact-item">
        <span>Телефон:</span>
        +7 812 445-60-60
      </a>
    </div>

    <button v-scroll-to="'#home-page'" class="scrollTop">
      <span>Наверх</span>
      <SvgIcon class="scrollTop__icon" name="arrow-long"/>
    </button>
  </div>
</div>
</template>

<script>
import FooterNav from "../Navigation/FooterNav";
import SvgIcon from "../SvgIcon";


export default {
  name: "FooterTop",
  components: {
    FooterNav,
    SvgIcon
  },
  props: {
    hideLink: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      navList: [
        {
          id: 0,
          title: 'Акции',
          href: '#promo'
        },
        // {
        //   id: 1,
        //   title: 'Калькулятор неисправностей',
        //   href: '#repair-calc'
        // },
        {
          id: 3,
          title: 'Услуги',
          href: '#modelService'
        },
        {
          id: 4,
          title: 'Быстрая запись',
          href: '#repair'
        },
        {
          id: 5,
          title: 'Галерея',
          href: '#gallery'
        },
        {
          id: 6,
          title: 'Контакты',
          href: '#map'
        }
      ],
      isHidden: false
    }
  },
  mounted() {
    this.isHidden = window.innerWidth > 768
  }
}
</script>

<style lang="scss" scoped>
.footer-top {
  padding: 71px 15px 46px;
  @include below($desktopSml) {
    padding: 50px 15px 10px;
  }
  &__content {
    display: grid;
    grid-template-columns: 25% 40% 25%;
    column-gap: 30px;
    position: relative;
    @include below(1200) {
      column-gap: 15px;
    }
  }
  &__logoWrap {
    >span {
      display: block;
      margin-top: 13px;
      font-size: 16px;
      line-height: 20px;
      color: #919191;
      @include below($desktopSml) {
        font-size: 14px;
      }
    }
  }
  &__logoImg {
    max-height: 50px;
    max-width: 100%;
  }
  &__logo {
    display: flex;
    align-items: center;
  }
  &__logoText {
    margin-left: 8px;
    font-weight: 500;
    line-height: 1.2;
    font-size: 18px;
    @include below($desktopSml) {
      font-size: 16px;
    }
    span {
      color: $additionalColor;
    }
  }
  &__contact-item {
    font-size: 16px;
    line-height: 110%;
    color: #fff;
    margin-bottom: 10px;
    span {
      display: block;
      font-weight: bold;
      margin-bottom: 5px;
      color: #858585;
    }
  }
}
.scrollTop {
  position: absolute;
  top: -15px;
  right: -55px;
  &__icon {
    width: 7px;
    height: 95px;
  }
  span {
    top: 40px;
    left: -40px;
    position: absolute;
    display: block;
    transform: rotate(-90deg);
  }
  @include below(1220) {
    display: none;
  }
}
</style>
