<template>
<section class="modelBrands" id="modelBrands" v-if="Array.isArray(brandsList) && brandsList.length">
  <div class="cnt">
    <div class="modelBrands__wrap">
      <div class="modelBrands__text">
        <h2 class="modelBrands__title title-sect">Что мы ремонтируем?</h2>
        <p class="modelBrands__descr descr-sect">Гарантируем высокое качество и доступные цены на ремонт и обслуживание вашего авто.</p>
      </div>

      <div class="brandsSlider">
        <div @click="openPopup" class="brandsSlider__wrapper"
             v-for="(car, i) in brands"
             :key="i"
        >
          <div class="brandsSlider__img">
            <img :src="car.img">
          </div>
          <div class="brandsSlider__title">{{car.name}}</div>
          <div class="brandsSlider__subtitle" v-if="car.subtitle">{{car.subtitle}}</div>
        </div>
      </div>
      </div>
  </div>
  <FlyingObj :hidden-xl="true" bottom="-230px" right="0px" name="flying-object-2"/>
</section>
</template>

<script>
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import "swiper/css/swiper.css"
import FlyingObj from "../Object/FlyingObj";
import SvgIcon from "../SvgIcon";
import CustomInput from "../elements/CustomInput";
import { bus } from "../bus";

export default {
  name: "ModelBrands",
  props: {
    brandsList: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      swiperOption: {
        slidesPerView: 2,
        slidesPerColumn: 2,
        sliderPerGroup: 3,
        spaceBetween: 20,
        navigation: {
          nextEl: '.brandsSlider__next',
          prevEl: '.brandsSlider__prev',
        },
        pagination: {
          el: '.brandsSlider__pagination',
          type: 'progressbar',
          clickable: false,
        },
        breakpoints: {
          1440: {
            slidesPerView: 3,
            sliderPerGroup: 3,
          },
          1024: {
            slidesPerView: 3,
          },
          450: {
            slidesPerView: 3,
          }
        }
      },
      search: '',
      brands: this.brandsList
    }
  },
  watch: {
    search() {
      this.brands = this.brandsList.filter((value) => {
          return (value.name.toLowerCase()).indexOf(this.search.toLowerCase()) != -1;
      })
    }
  },
  components: {
    FlyingObj,
    Swiper,
    SwiperSlide,
    SvgIcon,
    CustomInput
  },
  directives: {
    swiper: directive
  },
  methods: {
    openPopup() {
      bus.$emit('togglePopup', {
        title: 'Получить больше информации',
        btnText: 'Отправить'
      });
    }
  }
}
</script>

<style lang="scss">
.modelBrands {
  position: relative;
  padding: 35px 0;
  &__text {
     width: calc(100% / 3);
     margin-right: 30px;
    @include below($desktopDef) {
      width: calc(50% - 30px);
    }
    @include below($desktopSml) {
      width: calc(50% - 30px);
    }
    @include below($tablet) {
      width: 100%;
      margin-right: 0;
    }
   }
  &__title {
     line-height: 90%;
     letter-spacing: 0.01em;
     color: #ef850d;
   }
  &__descr {
     color: #11163F;
   }
  &__wrap {
     display: flex;
     justify-content: space-between;
     align-items: center;
    @include below($tablet) {
      flex-direction: column;
    }
 }
  &__search {
    margin-top: 20px;
  }
  &__searchTitle {
    font-weight: bold;
    font-size: 16px;
    color: #11163F;
    margin-bottom: 13px;
  }
  &__searchInput {
    width: 285px;
  }
}
.brandsSlider {
  width: calc(100% * (2/3) - 60px);
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  @include below($desktopDef) {
    width: 50%;
  }
  @include below($desktopSml) {
    width: 50%;
  }
  @include below($tablet) {
    margin-top: 18px;
    margin-bottom: 18px;
    width: 100%;
  }
  @include below($mobileLrg) {
    grid-template-columns: 1fr 1fr;
  }
  .swiper-wrapper {
    margin: 0 auto !important;
  }
  &__container {
    padding: 10px;
    height: 380px;
    width: 100%;
  }
  &__slide {
    background: #FFFFFF;
    cursor: pointer;
  }
  &__wrapper {
    height: 170px;
    position: relative;
    border-radius: 6px 6px 0px 0px;
    box-shadow: 0px 0px 8px rgba(34, 34, 34, 0.15);
    position: relative;
  }
  &__img {
    height: calc(100% - 40px);
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-height: 80%;
      max-width: 90%;
    }
  }
  &__title {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 40px;
    font-weight: bold;
    font-size: 18px;
    color: #11163F;
    background: #F5F5F5;
  }
  &__subtitle {
    background: $additionalColor;
    font-size: 12px;
    padding: 2px 8px;
    color: white;
    border-radius: 6px;
    position: absolute;
    top: 5px;
    left: 5px;
  }
  &__next, &__prev {
    position: absolute;
    top: 50%;
    cursor: pointer;
    @include below($desktopSml) {
      display: none;
    }
  }
  &__next {
    right: -30px;
    @include below($desktopDef) {
      right: -15px;
    }
  }
  &__prev {
    transform: rotate(180deg);
    left: -30px;
    @include below($desktopDef) {
      left: -15px;
    }
  }
  &__paginationWrap {
    display: flex;
    width: 100%;
    position: absolute;
    bottom: -26px;
    right: 0;
    justify-content: space-between;
    align-items: center;
  }
  &__pagination {
    width: calc(100% - 60px);
    height: 6px;
    background: #EFEFEF;
    border-radius: 12px;
    position: relative;
    .swiper-pagination-progressbar-fill {
      width: 100%;
      height: 4px;
      background: #11163F;
      border-radius: 12px;
      overflow: hidden;
      top: 1px;
      left: 5px;
    }
  }
  &__paginationIcon {
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
