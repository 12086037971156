<template>
  <header class="header">
      <HeaderFixed/>
      <HeaderSlider v-if="!hideSlider" :slideList="slideList"/>
  </header>
</template>

<script>
import HeaderFixed from "./HeaderFixed";
import HeaderSlider from "../Slider/HeaderSlider";
import 'swiper/swiper.scss';

export default {
  name: "Header",
  components: {
    HeaderFixed,
    HeaderSlider
  },
  props: {
    hideSlider: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      slideList: [
        {
          title: 'Доступная и всегда обоснованная <span>СТОИМОСТЬ РЕМОНТА</span>',
          btnText: 'Подробнее',
          img: '/img/slider/first_1920-670.jpg',
          imgTablet: '/img/slider/first_1920-670.jpg',
          imgMobile: '/img/slider/first_1920-670.jpg'
        },
        {
          title: '<span>Индивидуальный</span> подход к каждому клиенту',
          subtitle: 'Всегда учитываются пожелания и потребности, подбирается самый оптимальный вариант',
          btnText: 'Подробнее',
          img: '/img/slider/second_1920-670.jpg',
          imgTablet: '/img/slider/second_1020-500.jpg',
          imgMobile: '/img/slider/second_568-500.jpg'
        },
        {
          title: 'Качественный и долговечный и <span>РЕМОНТ ВСЕХ УЗЛОВ</span>',
          subtitle: 'Мы выполняем ремонт любых узлов. \n' +
              'Не тратьте время понапрасну там, \n' +
              'где этого можно избежать.',
          btnText: 'Подробнее',
          img: '/img/slider/third_1920-670.jpg',
          imgTablet: '/img/slider/third_1920-670.jpg',
          imgMobile: '/img/slider/third_1920-670.jpg'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.header {

}
</style>
