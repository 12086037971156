<template>
<div id="scrollTop" v-scroll-to="'#home-page'" :style="{'opacity': opacity}" class="scrollTopFixed" v-if="isHidden">
  <SvgIcon name="arrow-top"/>
</div>
</template>

<script>
import SvgIcon from "@/components/SvgIcon";



export default {
  name: "ScrollTop",
  components: {
    SvgIcon
  },
  data() {
    return {
      isHidden: false,
      opacity: 1
    }
  },
  mounted() {
    this.isHidden = window.innerWidth < 768;
    window.addEventListener('scroll', this.toggleScrollTop)
  },
  methods: {
    toggleScrollTop() {
      if (pageYOffset > 800) {
        this.opacity = 1;
      } else {
        this.opacity = 0;
      }
    }
  }
}
</script>

<style lang="scss">
.scrollTopFixed {
  position: fixed;
  right: 27px;
  bottom: 27px;
  background: #FFFFFF;
  border: 1px solid #E6E6E6;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.14);
  width: 57px;
  height: 57px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 99;
  transition: opacity 0.25s ease;
}
</style>