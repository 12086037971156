import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Politic from '../views/Politic.vue'
import NotFound from '../views/NotFound.vue'

Vue.use(VueRouter)


const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/politic',
    name: 'Politic',
    component: Politic
  },
  {
    path: '*',
    name: 'NotFound',
    component: NotFound
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
