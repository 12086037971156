<template>
  <div class="before-footer">
    <div class="cnt">
      <h2 class="before-footer__title">Другие услуги</h2>
      <div class="before-footer__cnt ">
        <a  style="background: url(img/link-1.png) center center/cover no-repeat"
            href="https://starblesk.com/"
            target="_blank"
            class="before-footer__item"
        >
          <span>Детейлинг</span>
        </a>
        <a  style="background: url(img/link-2.png) center center/cover no-repeat"
            href="https://mb-zvezdanevy.ru/"
            target="_blank"
            class="before-footer__item"
        >
          <span>Автомобили в продаже</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BeforeFooter"
}
</script>

<style lang="scss" scoped>
.before-footer {
  padding: 60px 0;
  @include below($desktopSml) {
    padding: 30px 0;
  }
  @include below($mobileDef) {
    padding: 20px 0;
  }
  &__title {
    font-size: 38px;
    font-weight: 900;
    line-height: 1.2;
    margin-bottom: 30px;
    @include below($tablet) {
      font-size: 30px;
    }
  }
  &__cnt {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 230px;
    gap: 30px;
    @include below($desktopSml) {
      grid-template-columns: 1fr;
      grid-template-rows: 230px 230px;
    }
    @include below($mobileLrg) {
      grid-template-rows: 170px 170px;
      gap: 15px;
    }
  }
  &__item {
    font-size: 30px;
    color: white;
    height: 100%;
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding: 15px 25px;
    overflow: hidden;
    position: relative;
    @include below($tablet) {
      font-size: 26px;
    }
    @include below($mobileLrg) {
      font-size: 22px;
      padding: 10px 18px;
    }
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.6);
      transition: .2s ease;
    }
    span {
      position: relative;
      z-index: 1;
    }
    &:hover:after {
      background: rgba(0, 0, 0, .2);
    }
  }
}
</style>
