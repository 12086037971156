<template>
  <footer class="footer">
    <FooterTop :hideLink="hideLink"/>
    <FooterBottom />
  </footer>
</template>

<script>
import FooterBottom from "./FooterBottom";
import FooterTop from "./FooterTop";


export default {
  name: "Footer",
  components: {
    FooterTop,
    FooterBottom
  },
  props: {
    hideLink: {
      type: Boolean,
      default: false
    }
  },
}
</script>

<style lang="scss" scoped>
.footer {
  background: #000;
  color: #fff;
}
</style>
