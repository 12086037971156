<template>
  <div class="home" id="home-page">
    <header class="header-top">
      <div class="header-top__cnt">
        <router-link to="/" class="header-top__logo">
          <img src="@/assets/images/logo.png" alt="logo" class="header-top__logoImg">
          <div class="header-top__logoText">
            Звезда&nbsp;Невы <span>Сервис</span>
          </div>
        </router-link>
        <div class="m-nav" :class="{active: activeMenu}" v-if="!isDesktop">
          <div class="m-nav__left">
            <div class="m-nav__worktime" v-if="!isMobile">
              <span>Пн-Вс с 9:00 до 21:00</span>
            </div>
          </div>
          <div class="m-nav__right">
            <div class="m-nav__worktime m-nav__worktime--mobile" v-if="isMobile">
              <span>Пн-Вс с 9:00 до 21:00</span>
            </div>
            <button class="btn header-top__btn" @click="openPopup">Заказать звонок</button>
            <a href="https://yandex.ru/maps/?ll=30.353687%2C59.870782&mode=routes&rtext=~59.870782%2C30.353687&rtt=auto&z=15" target="_blank" class="m-nav__routeSet">
              <SvgIcon class="m-nav__icon" name="compass-white"/>
              <span class="m-nav__routeText">Проложить маршрут</span>
            </a>
          </div>
        </div>
        <button class="btn header-top__btn" v-if="isDesktop" @click="openPopup">Заказать звонок</button>
        <div class="header-top__showMenu" :class="{'active': activeMenu}" @click="activeMenu = !activeMenu" v-if="!isDesktop"><span/><span/><span/></div>
      </div>
    </header>
    <Header hideSlider />
    <ModelText/>
    <Footer hideLink/>
    <ScrollTop/>
    <ContactPopup :options="popupOptions"/>
  </div>
</template>

<script>
import Header from "../components/Header/Header";
import ModelPromo from "../components/ModelPromo/ModelPromo";
import ModelOption from "../components/ModelOption/ModelOption";
import BrandsSlider from "../components/Slider/BrandsSlider";
import DelimiterSect from "../components/elements/DelimiterSect";
import ModelServices from "../components/ModelService/ModelService";
import RepairCalc from "../components/Calc/RepairCalc";
import ModelAdvantage from "../components/ModelAdvantage/ModelAdvantage";
import Gallery from "../components/Gallery/Gallery";
import ModelConsultation from "../components/ModelConsultation/ModelConsultation";
import Map from "../components/Map/Map";
import Footer from "../components/Footer/Footer";
import ContactPopup from "../components/Popup/ContactPopup";
import ScrollTop from "@/components/Scroll/ScrollTop";
import Nav from "@/components/Navigation/Nav";
import {bus} from "@/components/bus";
import SvgIcon from "@/components/SvgIcon";
import ModelText from "../components/ModelText/ModelText";

export default {
  name: 'Politic',
  components: {
    ModelText,
    SvgIcon,
    Header,
    Footer,
    ScrollTop,
    ContactPopup,
    Nav
  },
  mounted() {
    this.isDesktop = window.innerWidth > 1023;
    this.isMobile = window.innerWidth < 578;
  },
  data() {
    return {
      isDesktop: true,
      isMobile: true,
      activeMenu: false,
      popupOptions: {
        title: 'Консультация по телефону',
        btnText: 'Ждать звонка'
      },
    }
  },
  methods: {
    openPopup() {
      bus.$emit('togglePopup', {
        title: 'Для записи в сервис оставьте свои данные',
        btnText: 'Записаться'
      });
    },
  }
}
</script>

<style lang="scss" scoped>
.header-top {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: #000;
  color: #fff;
  margin-top: 50px;
  &__cnt {
    position: relative;
    height: 100%;
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 17px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 1000;
    @include above($desktopSml) {
      padding: 12px 17px;
    }
  }
  &__logo {
    display: flex;
    align-items: center;
    transition: opacity 0.15s ease-in;
    @include below($desktopSml) {
      width: 170px;
      height: 70px;
    }
  }

  &__logoImg {
    max-width: 100%;
    max-height: 50px;
  }

  &__logoText {
    margin-left: 8px;
    font-weight: 500;
    line-height: 1.2;
    font-size: 18px;
    text-align: left;
    display: flex;
    flex-direction: column;
    @include below($desktopSml) {
      font-size: 14px;
    }

    span {
      color: $additionalColor;
    }
  }
  &__btn {
    padding: 13px 25px;
    cursor: pointer;
  }
  &__showMenu {
    width: 50px;
    height: 50px;
    padding: 15px 12px;
    background: $additionalColor;
    box-shadow: 0px 0px 8px rgba(34, 34, 34, 0.15);
    border-radius: 6px;
    z-index: 2;

    span {
      display: block;
      margin-bottom: 5px;
      background: #FFFFFF;
      border-radius: 5px;
      width: 100%;
      height: 3px;
      transition: all 0.25s ease;
      &:last-child {
        margin-bottom: 0;
      }
    }
    &.active {
      span:nth-child(1) {
        width: 120%;
        transform: rotate( 45deg) translate(5px, 8px);
      }
      span:nth-child(2) {
        display: none;
      }
      span:nth-child(3) {
        width: 120%;
        transform: rotate(-45deg) translate(-2px, -1px);
      }
    }
    @include above($desktopMd) {
      display: none;
    }
  }
}
.m-nav {
  position: absolute;
  bottom: 0;
  transform: translateY(100%);
  background: #2F3137;
  width: 100%;
  margin: 0 -17px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  overflow: hidden;
  max-height: 0;
  transition: all .5s ease;
  &.active {
    max-height: 1000px;
    padding: 30px 17px;
  }
  &__left {
    width: 60%;
    @include below($mobileLrg) {
      width: 100%;
      order: 1;
    }
  }
  &__right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    @include below($mobileLrg) {
      margin-bottom: 25px;
      width: 100%;
      align-items: flex-start;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
    }

  }
  &__routeSet {
    margin-top: 20px;
    display: flex;
    align-items: center;
  }
  &__routeText {
    font-size: 16px;
    color: $additionalColor;
    border-bottom: 1px dashed $additionalColor;
  }

  &__icon {
    margin-right: 4px;
    width: 20px;
    height: 20px;
  }

  &__nav {
    margin-bottom: 15px;
    @include below($mobileLrg) {
      margin-bottom: 0;
    }
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
  }
  &__item {
    width: 50%;
    @include below($mobileLrg) {
      width: 100%;
    }
  }
  &__link {
    display: block;
    font-weight: 500;
    font-size: 16px;
    padding: 0 15px 15px 0;
    color: white;
    @include below($mobileLrg) {
      padding: 8px 0;
    }
  }
  &__wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  &__worktime {
    font-size: 14px;
    opacity: .8;
    color: white;
    span {
      margin-right: 15px;
    }
    &--mobile {
      display: flex;
      flex-direction: column;
      width: 50%;
    }
  }
}
</style>
