<template>
  <div class="headerSlider__cnt">
    <swiper v-if="Array.isArray(slideList) && slideList.length"
            class="headerSlider" :options="swiperOption"
    >
      <swiper-slide
          v-for="(slide, i) in slideList"
          :key="i"
          class="headerSlider__slide">
        <picture class="headerSlider__bg-wrapper">
          <img :src="slide.img" class="headerSlider__bg headerSlider__bg--desctop" :alt="slide.title">
          <img :src="slide.imgTablet" class="headerSlider__bg headerSlider__bg--tablet" :alt="slide.title">
          <img :src="slide.imgMobile" class="headerSlider__bg headerSlider__bg--mobile" :alt="slide.title">
        </picture>
        <div class="cnt">
          <div class="headerSlider__content" v-if="slide.title || slide.subtitle || slide.btnText">
            <h2 class="headerSlider__title" v-if="slide.title" v-html=" slide.title "></h2>
            <span class="headerSlider__subtitle" v-if="slide.subtitle">{{ slide.subtitle }}</span>
            <button @click.prevent.stop="openPopup" class="btn headerSlider__btn" v-if="slide.btnText">{{ slide.btnText }}</button>
          </div>
        </div>
      </swiper-slide>
    </swiper>

    <div class="headerSlider__pagination" slot="pagination"></div>
    <div class="sliderBtnNext headerSlider__btnNext">
      <SvgIcon name="arrow"/>
    </div>
    <div class="sliderBtnPrev headerSlider__btnPrev">
      <SvgIcon name="arrow"/>
    </div>
  </div>

</template>

<script>
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import "swiper/css/swiper.css"
import SvgIcon from "../SvgIcon";
import {bus} from "../bus";


export default {
  name: "HeaderSlider",
  data() {
    return {
      swiperOption: {
        loop: false,
        slidesPerView: 1,
        navigation: {
          nextEl: '.headerSlider__btnNext',
          prevEl: '.headerSlider__btnPrev',
        },
        pagination: {
          el: '.headerSlider__pagination',
          bulletClass: 'headerSlider__paginationItem',
          bulletActiveClass: 'isActive',
          clickable: true,
          bulletElement: 'div'
        },
      }
    }
  },
  props: {
    slideList: {
      type: Array,
      default() {
        return []
      }
    }
  },
  components: {
    Swiper,
    SwiperSlide,
    SvgIcon
  },
  directives: {
    swiper: directive
  },
  methods: {
    openPopup() {
      bus.$emit('togglePopup', {
        title: 'Получить больше информации',
        btnText: 'Отправить'
      });
    }
  }
}
</script>

<style lang="scss">
.sliderBtnPrev {
  width: 45px;
  height: 45px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: border 0.15s ease-in;
  &:hover {
    border: 1px solid rgba(255, 255, 255, 1);
  }
}
.sliderBtnNext {
  width: 45px;
  height: 45px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(180deg);
  cursor: pointer;
  transition: border 0.15s ease-in;
  &:hover {
    border: 1px solid rgba(255, 255, 255, 1);
  }
}
.headerSlider {
  position: relative;
  height: 800px;
  @include below($desktopMd) {
    height: 630px;
  }
  &__cnt {
    position: relative;
  }
  &__btnNext {
    position: absolute;
    top: calc(50% - 23px);
    right: 7%;
    z-index: 8;
    @include below(1300) {
      display: none;
    }
    &.swiper-button-disabled {
      display: none;
    }
  }
  &__btnPrev {
    position: absolute;
    top: calc(50% - 23px);
    left: 7%;
    z-index: 8;
    @include below(1300) {
      display: none;
    }
    &.swiper-button-disabled {
      display: none;
    }
  }
  &__slide {
    position: relative;
    display: flex;
    justify-content: center;
    > .cnt {
      position: relative;
      z-index: 2;
    }
  }
  &__bg {
    position: absolute;
    left: 0;
    top: 0;
    display: none;
    width: 100%;
    height: 100%;
    object-fit: cover;
    &-wrapper {
      position: absolute;
      left: 0;
      width: 100%;
      height: 100%;
    }
    &--desctop {
      @include above($desktopSml) {
        display: block;
      }
    }
    &--tablet {
      @include below($desktopSml) {
        display: block;
      }
      @include above($tablet) {
        display: block;
      }
    }
    &--mobile {
      @include below($tablet) {
        display: block;
      }
    }
  }
  &__pagination {
    position: absolute;
    width: 100px;
    left: 0;
    right: 0;
    margin: auto;
    bottom: 120px;
    z-index: 8;
    display: flex;
    @include below($desktopSml) {
      display: none;
    }
  }
  &__paginationItem {
    position: relative;
    width: 8px;
    height: 8px;
    background: #FFFFFF;
    opacity: 0.7;
    border-radius: 50%;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    margin: 0 10px;
    cursor: pointer;
    transition: opacity 0.2s ease-in;
    &:hover {
      opacity: 1;
    }
    &.isActive:before {
      position: absolute;
      content: '';
      width: 18px;
      height: 18px;
      top: -5px;
      left: -5px;
      opacity: 0.6;
      border: 1px solid #FFFFFF;
      border-radius: 50%;
      filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.15));
    }
  }
  &__content {
    margin-top: 170px;
    max-width: 75%;
    @include below($desktopMd) {
      margin-top: 50px;
      max-width: 70%;
    }
    @include below($desktopSml) {
      margin-top: 150px;
      max-width: 60%;
    }
    @include below($tablet) {
      max-width: 70%;
    }
  }
  &__title {
    font-weight: 800;
    font-size: 58px;
    line-height: 110%;
    color: #FFFFFF;
    span {
      color: $additionalDarkColor;
    }
    @include below($mobileLrg) {
      font-size: 24px;
    }
    @include below($desktopSml) {
      font-size: 32px;
    }
  }
  &__subtitle {
    display: block;
    padding: 3px 5px;
    margin-top: 20px;
    background-color: $additionalColor;
    color: #FFFFFF;
    font-size: 36px;
    @include below($desktopSml) {

      font-size: 26px;
      max-width: 60%;
    }
    @include below($tablet) {
      max-width: 100%;
    }
    @include below($mobileLrg) {
      font-size: 15px;
    }
  }
  &__btn {
    margin-top: 40px;
    padding: 15px 62px;
    @include below($tablet) {
      margin-top: 20px;
    }
  }
}

</style>
