<template>
  <div class="home" id="home-page">
    <header class="header-top">
      <div class="header-top__cnt">
        <router-link to="#" class="header-top__logo">
          <img src="@/assets/images/logo.png" alt="logo" class="header-top__logoImg">
          <div class="header-top__logoText">
            Звезда&nbsp;Невы <span>Сервис</span>
          </div>
        </router-link>
        <Nav
          :navList="navList"
          :isActiveMenu="activeMenu"
        />
        <div class="m-nav" :class="{active: activeMenu}" v-if="!isDesktop">
          <div class="m-nav__left">
            <nav class="m-nav__nav">
              <ul class="m-nav__list" v-if="Array.isArray(navList) && navList.length">
                <li v-scroll-to="item.href" class="m-nav__item" v-for="item in navList" :key="item.id">
                  <a class="m-nav__link">{{ item.title }}</a>
                </li>
              </ul>
            </nav>
            <div class="m-nav__worktime" v-if="!isMobile">
              <span>Пн-Вс с 9:00 до 21:00</span>
            </div>
          </div>
          <div class="m-nav__right">
            <div class="m-nav__worktime m-nav__worktime--mobile" v-if="isMobile">
              <span>Пн-Вс с 9:00 до 21:00</span>
            </div>
            <button class="btn header-top__btn" @click="openPopup">Заказать звонок</button>
            <a href="https://yandex.ru/maps/?ll=30.353687%2C59.870782&mode=routes&rtext=~59.870782%2C30.353687&rtt=auto&z=15" target="_blank" class="m-nav__routeSet">
              <SvgIcon class="m-nav__icon" name="compass-white"/>
              <span class="m-nav__routeText">Проложить маршрут</span>
            </a>
          </div>
        </div>
        <button class="btn header-top__btn" v-if="isDesktop" @click="openPopup">Заказать звонок</button>
        <div class="header-top__showMenu" :class="{'active': activeMenu}" @click="activeMenu = !activeMenu" v-if="!isDesktop"><span/><span/><span/></div>
      </div>
    </header>
    <Header/>
    <ModelPromo/>
    <BrandsSlider :brandsList="brandsList"/>
    <DelimiterSect/>
    <ModelServices :items="serviceList"/>
    <ModelWorks />
    <RepairCalc/>
    <ModelAdvantage :list="advList"/>
    <ModelCalc />
    <ModelIndividual />
    <Gallery :slideList="galleryList"/>
    <ModelConsultation :list="consList"/>
    <Map/>
    <BeforeFooter />
    <Footer/>
    <ContactPopup :options="popupOptions"/>
    <SuccessPopup />
    <ScrollTop/>
  </div>
</template>

<script>
import Header from "../components/Header/Header";
import ModelPromo from "../components/ModelPromo/ModelPromo";
import ModelOption from "../components/ModelOption/ModelOption";
import BrandsSlider from "../components/Slider/BrandsSlider";
import DelimiterSect from "../components/elements/DelimiterSect";
import ModelServices from "../components/ModelService/ModelService";
import ModelWorks from "../components/ModelService/ModelWorks";
import RepairCalc from "../components/Calc/RepairCalc";
import ModelAdvantage from "../components/ModelAdvantage/ModelAdvantage";
import ModelCalc from "../components/ModelAdvantage/ModelCalc";
import Gallery from "../components/Gallery/Gallery";
import ModelConsultation from "../components/ModelConsultation/ModelConsultation";
import ModelIndividual from "../components/ModelConsultation/ModelIndividual";
import Map from "../components/Map/Map";
import Footer from "../components/Footer/Footer";
import ContactPopup from "@/components/Popup/ContactPopup";
import SuccessPopup from "@/components/Popup/SuccessPopup";
import ScrollTop from "@/components/Scroll/ScrollTop";
import Nav from "@/components/Navigation/Nav";
import BeforeFooter from "@/components/Footer/BeforeFooter";
import {bus} from "@/components/bus";
import SvgIcon from "@/components/SvgIcon";

export default {
  name: 'Home',
  components: {
    SvgIcon,
    BrandsSlider,
    Header,
    ModelPromo,
    ModelOption,
    DelimiterSect,
    ModelServices,
    RepairCalc,
    ModelAdvantage,
    Gallery,
    ModelConsultation,
    Map,
    Footer,
    ContactPopup,
    ScrollTop,
    Nav,
    SuccessPopup,
    ModelIndividual,
    ModelWorks,
    BeforeFooter,
    ModelCalc
  },
  mounted() {
    this.isDesktop = window.innerWidth > 1023;
    this.isMobile = window.innerWidth < 578;
  },
  data() {
    return {
      isDesktop: true,
      isMobile: true,
      activeMenu: false,
      brandsList: [
        {
          img: 'img/brands/Audi.svg',
          name: 'Audi'
        },
        {
          img: 'img/brands/Volvo.svg',
          name: 'Volvo'
        },
        {
          img: 'img/brands/Mercedes.svg',
          name: 'Mercedes-Benz',
          subtitle: 'Не старше 2010 г.'
        },
        // {
        //   img: 'img/brands/BMW.svg',
        //   name: 'BMW'
        // },
        // {
        //   img: 'img/brands/Citroen.svg',
        //   name: 'Citroen'
        // }
        // ,{
        //   img: 'img/brands/Ford.svg',
        //   name: 'Ford'
        // },
        // {
        //   img: 'img/brands/KIA.svg',
        //   name: 'KIA'
        // },
        // {
        //   img: 'img/brands/Lada.svg',
        //   name: 'Lada'
        // },
        // {
        //   img: 'img/brands/Mitsubishi.svg',
        //   name: 'Mitsubishi'
        // },
        // {
        //   img: 'img/brands/Nissan.svg',
        //   name: 'Nissan'
        // },
        // {
        //   img: 'img/brands/Opel.svg',
        //   name: 'Opel'
        // },
        // {
        //   img: 'img/brands/peugeot.svg',
        //   name: 'Peugeot'
        // },
        // {
        //   img: 'img/brands/Renault.svg',
        //   name: 'Renault'
        // },
        {
          img: 'img/brands/Skoda.svg',
          name: 'Skoda'
        },
        {
          img: 'img/brands/Volkswagen.svg',
          name: 'Volkswagen'
        },
        // {
        //   img: 'img/brands/Toyota.svg',
        //   name: 'Toyota'
        // },
      ],
      serviceList: [
        {
          title: 'Техническое обслуживание',
          img: 'img/service/service-1.png',
          text: ['Проведение ТО','Заправка автокондиционера','Шиномонтаж и балансировка колёс','Замена технических жидкостей','Мойка автомобилей']
        },
        {
          title: 'Диагностика автомобиля',
          img: 'img/service/service-2.png',
          text: ['Компьютерная диагностика','Регулировка развал-схождение','Диагностика перед покупкой','Диагностика амортизаторов на вибростенде']
        },
        {
          title: 'Агрегатный и слесарный ремонт',
          img: 'img/service/service-3.png',
          text: ['Ремонт двигателя','Ремонт подвески','Ремонт мкпп','Ремонт тормозной системы', 'Полная замена масла в АКПП AISIN на автомобилях Volvo']

        },
        {
          title: 'Ремонт электрооборудования',
          img: 'img/service/service-4.png',
          text: ['Ремонт стартера и генератора','Регулировка света фар','Установка камер заднего вида','Установка видео регистраторов','Установка охранных систем и дополнительного оборудования']
        }
      ],
      advList: [
        {
          icon: 'class',
          title: 'Гарантия на работы и запасные части'
        },
        {
          icon: 'star',
          title: 'Наш автосервис оснащён необходимым оборудованием'
        },
        {
          icon: 'fast-time',
          title: 'Скорость. Оперативность работ и быстрый ремонт Вашего авто'
        },
        {
          icon: 'phone-setting',
          title: 'Онлайн согласование работ'
        },
        {
          icon: 'people-settings',
          title: 'Мастера с опытом работы в дилерском центре'
        },
        {
          icon: 'mark-price',
          title: ' Наши цены более привлекательны, чем на дилерских станциях'
        }
      ],
      consList: [
        {
          icon: 'cons-1',
          title: 'Используем средства индивидуальной защиты'
        },
        {
          icon: 'cons-2',
          title: 'Бесконтактно производим выдачу / приемку автомобиля'
        },
        {
          icon: 'cons-3',
          title: 'Следим за соблюдением дистанции'
        },
        {
          icon: 'cons-4',
          title: 'Дезинфицируем рабочие поверхности и салоны после себя'
        }
      ],
      galleryList: [
          'img/gallery/1.jpg',
          'img/gallery/2.jpg',
          'img/gallery/3.jpg',
          'img/gallery/4.jpg',
          'img/gallery/5.jpg',
          'img/gallery/gallery-7.jpg',
          'img/gallery/gallery-8.jpg'
      ],
      popupOptions: {
        title: 'Консультация по телефону',
        btnText: 'Ждавть звонка'
      },
      navList: [
        {
          id: 0,
          title: 'Акции',
          href: '#promo'
        },
        {
          id: 3,
          title: 'Услуги',
          href: '#modelService'
        },
        {
          id: 4,
          title: 'Быстрая запись',
          href: '#repair'
        },
        {
          id: 5,
          title: 'Галерея',
          href: '#gallery'
        },
        {
          id: 6,
          title: 'Контакты',
          href: '#map'
        }
      ],
    }
  },
  methods: {
    openPopup() {
      bus.$emit('togglePopup', {
        title: 'Для записи в сервис оставьте свои данные',
        btnText: 'Записаться'
      });
    },
  }
}
</script>

<style lang="scss" scoped>
.header-top {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: #000;
  color: #fff;
  margin-top: 50px;
  &__cnt {
    position: relative;
    height: 100%;
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 17px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 1000;
    @include above($desktopSml) {
      padding: 12px 17px;
    }
  }
  &__logo {
    display: flex;
    align-items: center;
    transition: opacity 0.15s ease-in;
    @include below($desktopSml) {
      width: 170px;
      height: 70px;
    }
  }

  &__logoImg {
    max-width: 100%;
    max-height: 50px;
  }

  &__logoText {
    margin-left: 8px;
    font-weight: 500;
    line-height: 1.2;
    font-size: 18px;
    text-align: left;
    display: flex;
    flex-direction: column;
    @include below($desktopSml) {
      font-size: 14px;
    }

    span {
      color: $additionalColor;
    }
  }
  &__btn {
    padding: 13px 25px;
    cursor: pointer;
  }
  &__showMenu {
    width: 50px;
    height: 50px;
    padding: 15px 12px;
    background: $additionalColor;
    box-shadow: 0px 0px 8px rgba(34, 34, 34, 0.15);
    border-radius: 6px;
    z-index: 2;

    span {
      display: block;
      margin-bottom: 5px;
      background: #FFFFFF;
      border-radius: 5px;
      width: 100%;
      height: 3px;
      transition: all 0.25s ease;
      &:last-child {
        margin-bottom: 0;
      }
    }
    &.active {
      span:nth-child(1) {
        width: 120%;
        transform: rotate( 45deg) translate(5px, 8px);
      }
      span:nth-child(2) {
        display: none;
      }
      span:nth-child(3) {
        width: 120%;
        transform: rotate(-45deg) translate(-2px, -1px);
      }
    }
    @include above($desktopMd) {
      display: none;
    }
  }
}
.m-nav {
  position: absolute;
  bottom: 0;
  transform: translateY(100%);
  background: #2F3137;
  width: 100%;
  margin: 0 -17px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  overflow: hidden;
  max-height: 0;
  transition: all .5s ease;
  &.active {
    max-height: 1000px;
    padding: 30px 17px;
  }
  &__left {
    width: 60%;
    @include below($mobileLrg) {
      width: 100%;
      order: 1;
    }
  }
  &__right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    @include below($mobileLrg) {
      margin-bottom: 25px;
      width: 100%;
      align-items: flex-start;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
    }

  }
  &__routeSet {
    margin-top: 20px;
    display: flex;
    align-items: center;
  }
  &__routeText {
    font-size: 16px;
    color: $additionalColor;
    border-bottom: 1px dashed $additionalColor;
  }

  &__icon {
    margin-right: 4px;
    width: 20px;
    height: 20px;
  }

  &__nav {
    margin-bottom: 15px;
    @include below($mobileLrg) {
      margin-bottom: 0;
    }
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
  }
  &__item {
    width: 50%;
    @include below($mobileLrg) {
      width: 100%;
    }
  }
  &__link {
    display: block;
    font-weight: 500;
    font-size: 16px;
    padding: 0 15px 15px 0;
    color: white;
    @include below($mobileLrg) {
      padding: 8px 0;
    }
  }
  &__wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  &__worktime {
    font-size: 14px;
    opacity: .8;
    color: white;
    span {
      margin-right: 15px;
    }
    &--mobile {
      display: flex;
      flex-direction: column;
      width: 50%;
    }
  }
}
</style>
