<template>
<div class="delimiter" v-if="isVisible">
  <img src="@/assets/images/delimiter/delimiter-2.png" alt="">
  <img class="delimiter-child" src="@/assets/images/delimiter/delimiter-1.png" alt="">
</div>
</template>

<script>
export default {
  name: "DelimiterSect",
  data() {
    return {
      isVisible: true
    }
  },
  mounted() {
    this.isVisible = window.innerWidth > 768
  }
}
</script>

<style lang="scss" scoped>
.delimiter {
  position: relative;
  margin-top: 45px;
  margin-bottom: 10px;
  @include below($desktopSml) {
    margin-bottom: 2px;
  }
  &-child {
    left: 0;
    position: absolute;
    top: 17px;
    @include below($desktopSml) {
      top: 15px;
    }
 }
}
</style>