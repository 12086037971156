<template>
<div class="footer-bottom">
  <div class="cnt">
    <div class="footer-bottom__content">
      <div class="footer-bottom__logoWrap" v-if="!isHidden">
        <router-link to="/" class="footer-bottom__logo">
          <img src="@/assets/images/logo.png" alt="logo" class="footer-bottom__logoImg">
          <div class="footer-bottom__logoText">
            Звезда&nbsp;Невы <span>Сервис</span>
          </div>
        </router-link>
        <span>2022 «Звезда Невы»</span>
      </div>
      <div class="footer-bottom__dev" v-if="!isHidden">
        Разработано:
        <a href="https://lego-car.ru" class="legocar" target="_blank">LegoCar</a>
      </div>
      <div class="footer-bottom__text">
        Любое использование материалов сайта без разрешения запрещено.
        Данный сайт несет информационный характер, материалы и цены, размещенные на сайте, не являются публичной офертой
      </div>
      <div class="footer-bottom__dev" v-if="isHidden">
        Разработано:
        <a href="https://lego-car.ru" class="legocar" target="_blank">LegoCar</a>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "FooterBottom",
  data() {
    return {
      isHidden: false
    }
  },
  mounted() {
    this.isHidden = window.innerWidth > 768
  }
}
</script>

<style lang="scss" scoped>
.footer-bottom {
  min-height: 148px;
  border-top: 1px solid #2d2d2d;
  padding: 20px 0 40px 0;
  @include below($tablet) {
    margin-top: 35px;
    border: none;
  }
  .cnt {
    height: 100%;
  }
  &__content {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include below($tablet) {
      flex-wrap: wrap;
    }
  }
  &__text {
    font-size: 16px;
    line-height: 20px;
    color: #7B7B7B;
    width: 50%;
    @include below($tablet) {
      width: 100%;
      margin-top: 15px;
    }
  }
  &__dev {
    font-size: 16px;
    line-height: 16px;
    color: #919191;
    display: flex;
    flex-direction: column;
    a {
      font-weight: bold;
      font-size: 28px;
      line-height: 35px;
      color: #919191;
    }
  }
  &__logoWrap {
    width: 50%;
    @include below($mobileSml) {
      width: 100%;
      margin-bottom: 20px;
    }
    >span {
      font-size: 16px;
      line-height: 20px;
      color: #919191;
      white-space: pre;
    }
  }
  &__logoImg {
    height: 100%;
    max-width: 60px;
  }
  &__logo {
    display: flex;
    align-items: center;
  }
  &__logoText {
    margin-left: 8px;
    font-weight: 500;
    line-height: 1.2;
    font-size: 18px;
    span {
      display: block;
      color: $additionalColor;
    }
  }
}
.legocar {
  cursor: pointer;
}
</style>
