<template>
  <nav class="nav" v-if="isHidden">
    <ul class="nav__list" v-if="Array.isArray(navList) && navList.length">
      <li v-scroll-to="item.href" class="nav__item" v-for="item in navList" :key="item.id">
        <a class="nav__link">{{ item.title }}</a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "Navigation",
  props: {
    navList: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      isHidden: false
    }
  },
  mounted() {
    this.isHidden = window.innerWidth > 1023
  }
}
</script>

<style lang="scss" scoped>
.nav{
  display: flex;
  align-items: center;
  &__list {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__item {
    margin: 0 15px;
    color: #fff;
    font-weight: 500;
    cursor: pointer;
    padding: 15px 0;
    &:first-child {
      margin-left: 0;
    }
  }
  &__tel, &__address {
    font-weight: 500;
    font-size: 14px;
    color: #E8E8E8;
    text-align: center;
    width: 100%;
    display: block;
    margin-bottom: 10px;
  }

}
</style>
