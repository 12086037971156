<template>
<section class="advantage" v-if="Array.isArray(list) && list.length">
  <div class="cnt">
    <div class="advantage__wrap">
      <div class="advantage__text">
        <h2 class="advantage__title title-sect">Множество
          плюсов</h2>
        <p class="advantage__descr descr-sect">Благодаря большому ряду преимуществ компании «Звезда Невы» вы можете смело доверить свой автомобиль нашим опытным специалистам.</p>
      </div>
      <div class="advantage__content">
        <div class="advantage__item" v-for="item in list">
          <SvgIcon class="advantage__icon" :name="item.icon"/>
          <div class="advantage__itemTitle">{{ item.title }}</div>
        </div>
      </div>
    </div>
  </div>
</section>
</template>

<script>
import SvgIcon from "../SvgIcon";
export default {
  name: "ModelAdvantage",
  props: {
    list: {
      type: Array,
      default() {
        return []
      }
    }
  },
  components: {
    SvgIcon
  }
}
</script>

<style lang="scss" scoped>
.advantage {
  position: relative;
  padding: 10px  0 35px 0;
  @include below($tablet) {
    padding-bottom: 20px;
  }
  &__text {
     margin-right: 30px;
    @include below($desktopSml) {
      width: 100%;
    }
    @include below($tablet) {
      width: 100%;
    }
   }
  &__title {
     line-height: 90%;
     letter-spacing: 0.01em;
     color: #ef850d;
   }
  &__descr {
     color: #11163F;
   }
  &__wrap {
    display: grid;
    grid-template-columns: 1fr 2fr;
    align-items: center;
    gap: 30px;
    @include below($desktopSml) {
      grid-template-columns: 1fr;
    }
  }
  &__content {
    display: grid;
    justify-content: end;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 200px;
    gap: 20px;
    @include below($desktopSml) {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 180px;
    }
    @include below($tablet) {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
    }
  }
  &__item {
    background: #FFFFFF;
    box-shadow: 0 4.27049px 21.3525px rgba(0, 0, 0, 0.15);
    margin-right: 20px;
    padding: 25px 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    &:last-child {
      margin-right: 0;
    }
    @include below($desktopSml) {
      flex-direction: row;
      padding: 20px;
      justify-content: flex-start;
      background: #F2F2F2;
      width: 100%;
      box-shadow: none;
      margin-bottom: 6px;
      margin-right: 0;
    }
    @include below($tablet) {
      padding: 20px 15px;
    }
  }
  &__itemTitle {
    font-weight: 500;
    font-size: 15px;
    line-height: 110%;
    text-align: center;
    color: #11163F;
    @include below($desktopSml) {
      font-weight: bold;
      text-align: left;
    }
  }
  &__icon {
    height: 80px;
    min-height: 80px;
    width: 100%;
    margin-bottom: 15px;
    @include below($desktopSml) {
      height: 57px;
      min-width: 60px;
      width: 60px;
      margin-right: 15px;
      margin-bottom: 0;
    }
  }
}
</style>
