<template>
<div class="ask">
  <div class="ask__bg">
    <img src="/img/form.png">
  </div>
  <div class="ask__content">
    <div class="cnt">
      <div class="ask__block">
        <h3 class="ask__title">Остались вопросы?</h3>
        <div class="ask__text">Оставьте свой телефон, мы свяжемся с Вами в течение нескольких минут и
          ответим на любые интересующие вопросы
        </div>
        <form class="ask__form">
          <div class="ask__row">
            <CustomInput :error="errorTel" @removeError="errorTel = false" class="ask__field" mask="+ 7 (###) ###-##-##" placeholder="Номер телефона" v-model="tel"/>
          </div>
          <div class="ask__row">
            <v-select class="ask__select" :searchable="false"
                      :clearable="false" v-model="brand" :options="['Audi', 'Volvo', 'Skoda', 'Volkswagen']" placeholder="Выберите марку" />
          </div>
          <div class="ask__row">
            <button @click.prevent="sendTicket" class="btn ask__btn">Получить консультацию</button>
          </div>
          <div class="ask__row">
            <p class="ask__text-sub">Нажимая кнопку "Получить консультаци", Вы соглашаетесь с <a href="politic" target="_blank">Политикой защиты данных</a></p>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import SvgIcon from "../SvgIcon";
import { bus } from "../bus";
import CustomInput from "@/components/elements/CustomInput";
import axios from "axios";

export default {
  name: "ModelConsultation",
  data() {
    return {
      errorTel: false,
      tel: '',
      brand: ''
    }
  },
  props: {
    list: {
      type: Array,
      default() {
        return []
      }
    }
  },
  components: {
    SvgIcon,
    CustomInput,
  },
  methods: {
    sendTicket() {
      this.errorTel = this.tel.length !== 19;
      if (this.errorTel)
        return
      const formData = {
        brand: this.brand,
        phone: this.tel,
        code: 'ask'
      }
      axios.post('/mail.php', formData).then(({data}) => {
        this.tel = '';
        this.brand = '';
        if (data.status === 'success') {
          bus.$emit('toggleSuccessPopup');
        } else {
          bus.$emit('toggleSuccessPopup', 'Ошибка при отправке заявки');
        }
      }).catch(() => {
        bus.$emit('toggleSuccessPopup', 'Ошибка при отправке заявки');
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.ask {
  position: relative;
  background-color: #727272;
  &__bg {
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
    img {
      height: 560px;
      width: 100%;
      object-fit: cover;
    }
    @include below($tablet) {
      display: none;
    }
  }
  &__content {
    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
    height: 100%;
    @include below($tablet) {
      position: static;
      height: initial;
      background-color: #646464;
    }
  }
  .cnt {
    height: 100%;
    @include below($tablet) {
      max-width: 375px;
      padding: 0 15px;
    }
  }
  &__block {
    padding: 20px 30px;
    width: 406px;
    height: 100%;
    color: #fff;
    position: relative;
    z-index: 2;
    margin-left: auto;
    &:before {
      content: '';
      width: 999px;
      height: 100%;
      position: absolute;
      top: 0;
      left: -60px;
      background: rgba(255, 255, 255, 0.3);
      backdrop-filter: blur(80px);
      z-index: -1;
      @include below($tablet) {
        display: none;
      }
    }
    @include below($tablet) {
      width: 100%;
      padding: 30px 0;
    }
  }
  &__title {
    margin: 0;
    font-size: 38px;
    font-weight: 900;
    text-transform: uppercase;
    line-height: 1.2;
    @include below($tablet) {
      font-size: 30px;
    }
  }
  &__text {
    font-size: 14px;
    line-height: 1.3;
    margin-top: 20px;
  }
  &__form {
    position: relative;
    z-index: 10;
    margin-top: 30px;
  }
  &__row:not(:first-child) {
    margin-top: 20px;
  }
  &__text-sub {
    font-size: 12px;
    line-height: 1.3;
  }
  &__text-sub a {
    border-bottom: 1px solid currentColor;
    outline: none;
    color: currentColor;
    text-decoration: none;
    transition: 0.2s;
  }
  &__field {
    color: #444444;
  }
  &__btn {
    display: block;
    width: 100%;
    padding: 12px;
  }
}
</style>
<style lang="scss">
.vs__selected {
  font-weight: normal;
  font-size: 16px;
  color: #595959;
}
.vs__dropdown-toggle {
  border-radius: 6px !important;
  padding: 10px !important;
  border: 1px solid #E3E3E3 !important;

  background: #FFFFFF !important;
  overflow: hidden;

  font-size: 16px;
  color: #595959;
}
body {
  .vs__dropdown-option {
    color: #666;
    padding: 10px 20px !important;
    &--selected {
      background-color: $additionalColor !important;
      color: white;
    }
    &--highlight {
      background-color: rgba(232, 129, 12, 0.4);
      color: white;
    }
  }
  .vs__dropdown-menu {
    padding: 0;
  }
}
</style>
