<template>
<section class="map" id="map">
  <yandex-map ymap-class="map-box" :coords="coords" :zoom="15" :controls="controls" :scroll-zoom="scrollZoom">
    <ymap-marker
        marker-type="placemark"
        :coords="[59.870782, 30.353687]"
        :markerId="1"
        hint-content="Звезда Невы"
        :balloon="{header: 'Звезда Невы', body: '+7 812 445-60-60', footer: 'Витебский проспект, 17к7, г. Санкт-Петербург'}"
        :icon="{color: 'orange', glyph: 'auto'}"
    />
  </yandex-map>
    <div class="contact">
      <div class="contact__wrap">
        <h2 class="contact__title">Контакты</h2>
        <div class="contact__worktime">
          Пн-Вс с 9:00 до 21:00
        </div>
        <a href="tel:+78124456060" class="contact__tel">
          Телефон:
          <div>+7 812 445-60-60</div>
        </a>
        <div class="contact__address">
          Адрес офиса:
          <div>Витебский проспект, 17к7, г. Санкт-Петербург</div>
        </div>
        <div class="contact__social">
<!--          <div class="contact__socialList">-->
<!--            <a href="https://viber.click/79867915000" class="contact__socialLink" target="_blank">-->
<!--              <SvgIcon name="viber"/>-->
<!--            </a>-->
<!--            <a href="https://api.whatsapp.com/send?phone=+79867915000&text=%D0%A5%D0%BE%D1%87%D1%83%20%D0%BF%D0%BE%D0%BB%D1%83%D1%87%D0%B8%D1%82%D1%8C%20%D0%BA%D0%BE%D0%BD%D1%81%D1%83%D0%BB%D1%8C%D1%82%D0%B0%D1%86%D0%B8%D1%8E" class="contact__socialLink" target="_blank">-->
<!--              <SvgIcon name="wats"/>-->
<!--            </a>-->
<!--&lt;!&ndash;            <router-link to="#" class="contact__socialLink">&ndash;&gt;-->
<!--&lt;!&ndash;              <SvgIcon name="telegram"/>&ndash;&gt;-->
<!--&lt;!&ndash;            </router-link>&ndash;&gt;-->
<!--            <a href="https://www.instagram.com/narod_service56" class="contact__socialLink" target="_blank">-->
<!--              <SvgIcon name="instagram"/>-->
<!--            </a>-->
<!--            <a href="https://vk.com/narod.service" class="contact__socialLink" target="_blank">-->
<!--              <SvgIcon name="vk"/>-->
<!--            </a>-->
<!--          </div>-->
<!--          <span>Мы в социальных сетях</span>-->

        </div>
      </div>
    </div>
</section>
</template>

<script>
import SvgIcon from "../SvgIcon";


export default {
  name: "Map",
  data () {
    return {
      coords: [59.870782, 30.343687],
      controls: ['fullscreenControl', 'searchControl', 'zoomControl'],
      scrollZoom: false,
    }
  },
  components: {
    SvgIcon
  }
}
</script>

<style lang="scss">
.map {
  position: relative;
  .cnt {
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    position: absolute;
  }
  &-box {
    width: 100%;
    height: 600px;
  }
}
.contact {
  position: absolute;
  top: 0;
  left: 15%;
  height: 100%;
  display: flex;
  align-items: center;
  @include below($desktopSml) {
    left: 30px;
    align-items: flex-end;
    padding-bottom: 5px;
  }
  @include below($desktopSml) {
    left: 15px;
  }
  @include below($tablet) {
    top: unset;
    bottom: 5px;
    height: unset;
    min-height: 210px;
  }
  &__wrap {
    width: 492px;
    height: 488px;
    background: #FFFFFF;
    box-shadow: 2px 2px 9px rgba(34, 34, 34, 0.2);
    padding: 55px 70px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include below($desktopSml) {
      height: 210px;
      width: 540px;
      padding: 35px 30px;
      flex-direction: row-reverse;
      flex-wrap: wrap;
    }
    @include below($tablet) {
      width: calc(100% - 15px);
      height: unset;
      min-height: 210px;
    }
  }
  &__title {
    font-weight: 800;
    font-size: 60px;
    color: #ef850d;
    @include below($desktopSml) {
      display: none;
    }
  }
  &__worktime {
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    color: #777B7E;
    margin-top: 15px;
    @include below($desktopSml) {
      margin-top: 0;
      width: 40%;
      font-size: 15px;
    }
    @include below($tablet) {
      width: 100%;
    }
    @include below($tablet) {
      margin-top: 15px;
      order: 1;
    }
  }
  &__tel, &__address {
    font-weight: bold;
    font-size: 18px;
    color: #777B7E;
    @include below($desktopSml) {
      font-size: 15px;
      width: 60%;
    }
    @include below($tablet) {
      width: 100%;
    }
    div {
      font-size: 24px;
      line-height: 30px;
      color: #33353B;
      @include below($desktopSml) {
        font-size: 20px;
      }
    }
  }
  &__tel {
    margin-top: 28px;
    @include below($desktopSml) {
      margin: 0;
      order: 0;
    }
    @include below($tablet) {
      order: -1;
    }
  }
  &__address {
    margin-top: 10px;
    @include below($desktopSml) {
      margin: 0;
      order: 1;
    }
    @include below($tablet) {
      order: 0;
      margin-top: 15px;
    }
  }
  &__social {
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    font-size: 18px;
    font-weight: 500;
    line-height: 23px;
    color: #777B7E;
    @include below($desktopSml) {
      align-items: flex-start;
      width: 40%;
      span {
        display: none;
      }
    }
    @include below($tablet) {
      width: 100%;
    }
    @include below($tablet) {
      margin-top: 15px;
      order: 2;
    }
  }
  &__socialList {
    display: flex;
  }
  &__socialLink {
    margin-right: 10px;
    svg {
      width: 36px;
      height: 36px;
    }
  }
}
</style>
