<template>
  <transition name="fade">
    <div class="popup" v-if="isActive">
      <div class="popup__modal">
        <div @click="closePopup" class="popup__close">
          <SvgIcon  class="popup__close" name="close"/>
        </div>
        <div class="popup__content">
          <div class="popup__title" v-if="optionForm.title">
            {{ optionForm.title }}
          </div>
          <form class="popup__form" @submit.prevent v-if="optionForm.btnText">
            <div class="popup__help">Укажите данные для связи</div>
            <CustomInput :error="errorName" @removeError="errorName = false" class="popup__formItem popup__input" placeholder="Как к вам обращаться" v-model="name"/>
            <CustomInput :error="errorTel" @removeError="errorTel = false" class="popup__formItem popup__input" mask="+ 7 (###) ###-##-##" placeholder="Номер телефона" v-model="tel"/>
            <CustomInput v-if="optionForm.vin" class="popup__formItem popup__input" placeholder="VIN-номер машины" v-model="vin"/>
            <CustomDatepicker v-if="optionForm.date" v-model="date" class="popup__formItem popup__input" placeholder="Дата" />
            <CustomTextarea v-if="optionForm.working" v-model="working" class="popup__formItem popup__input" placeholder="Желаемые работы" />
            <button class="popup__formItem btn popup__btn" @click.prevent="sendTicket">{{ optionForm.btnText }}</button>
            <div class="popup__politic">Нажимая кнопку "{{ optionForm.btnText }}", Вы соглашаетесь с <a href="politic" target="_blank">Политикой защиты данных</a></div>
          </form>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import SvgIcon from "../SvgIcon";
import CustomInput from "../elements/CustomInput";
import CustomTextarea from "../elements/CustomTextarea";
import axios from 'axios';
import { bus } from "../bus"
import CustomDatepicker from "@/components/elements/CustomDatepicker";

export default {
  name: "ContactPopup",
  data() {
    return {
      isActive: false,
      tel: '',
      name: '',
      vin: '',
      working: '',
      date: '',
      optionForm: this.options,
      errorName: false,
      errorTel: false
    }
  },
  components: {
    CustomDatepicker,
    SvgIcon,
    CustomInput,
    CustomTextarea
  },
  props: {
    options: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  mounted() {
    bus.$on('togglePopup', this.openPopup);
  },
  methods: {
    openPopup(options) {
      this.optionForm = options;
      this.isActive = true;

      document.addEventListener('click', (e) => {
        if(e.target.classList.contains('popup')) {
          this.closePopup()
        }
      })
    },
    closePopup() {
      this.tel = '';
      this.name = '';
      this.errorName = false;
      this.errorTel = false;
      this.isActive = false;
    },
    sendTicket() {
      this.errorName = false;
      this.errorTel = false;
      if (this.tel.length !== 19) {
        this.errorTel = true;
      }
      if (this.name === '') {
        this.errorName = true;
      }
      if (this.errorName || this.errorTel)
        return
      const formData = {
        name: this.name,
        phone: this.tel,
        code: 'order'
      }
      if (this.optionForm.vin)
        formData.vin = this.vin
      if (this.optionForm.working)
        formData.working = this.working
      if (this.optionForm.date)
        formData.date = this.date
      axios.post('/mail.php', formData).then(({data}) => {
        this.tel = '';
        this.name = '';
        this.vin = '';
        this.date = '';
        this.working = '';
        this.closePopup();
        if (data.status === 'success') {
          bus.$emit('toggleSuccessPopup');
        } else {
          bus.$emit('toggleSuccessPopup', 'Ошибка при отправке заявки');
        }
      }).catch(() => {
        this.closePopup();
        bus.$emit('toggleSuccessPopup', 'Ошибка при отправке заявки');
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.popup {
  position: fixed;
  z-index: 100;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
  }
  &__modal {
    position: relative;
    width: 500px;
    min-height: 540px;
    background: #FFFFFF;
    border-radius: 18px;
    padding: 80px 40px;
    z-index: 101;
  }
  &__content {
    min-height: 380px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &__help {
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    color: #93949B;
    margin-bottom: 25px;
  }
  &__politic {
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  color: #93949B;
  margin-bottom: 25px;
  }
  &__politic a {
    text-decoration: underline;
  }
  &__politic a:hover {
    text-decoration: none;
  }
  &__formItem {
    margin-bottom: 17px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__close {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }
  &__title {
    font-weight: 900;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #2F2F50;
    margin-bottom: 10px;
  }
  &__btn {
    background: #FBFBFB;
    border: 1.5px solid #DFDFDF;
    border-radius: 8px;
    font-weight: bold;
    font-size: 16px;
    width: 100%;
    padding: 14px 0;
    text-align: center;
    color: #3B3D52;
    transition: background-color 0.25s ease;
    &:hover {
      background: #f5f5f5;
    }
  }
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .25s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
</style>
