<template>
<section class="gallery" id="gallery" v-if="Array.isArray(slideList) && slideList.length">
  <div class="cnt">
    <div class="gallery__wrap">
      <div class="gallery__text">
        <span class="gallery__title title-sect">Галерея</span>
      </div>
      <div class="gallery__content">
        <div class="gallery__option">
        </div>
      </div>
      <div class="gallery__slideView">
        <swiper class="gallery__slider"
                :options="swiperOption"
                id="gallery-swiper"
        >
          <swiper-slide
              v-for="(img, i) in slideList"
              :key="i"
              :style="'background: url(' + img + ') center center/cover no-repeat'"
              class="gallery__slide"
          >
          </swiper-slide>
        </swiper>

        <div class="gallery__next">
          <SvgIcon name="arrow-default"/>
        </div>
        <div class="gallery__prev">
          <SvgIcon name="arrow-default"/>
        </div>
        <div class="gallery__pagWrap">
          <div class="gallery__pagination"></div>
        </div>
      </div>
    </div>
  </div>
</section>
</template>

<script>
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import "swiper/css/swiper.css"
import SvgIcon from "../SvgIcon";

export default {
  name: "Gallery",
  props: {
    slideList: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      swiperOption: {
        slidesPerView: 1,
        spaceBetween: 20,
        navigation: {
          nextEl: '.gallery__next',
          prevEl: '.gallery__prev',
        },
        pagination: {
          el: '.gallery__pagination',
          type: 'fraction'
        },
        slideActiveClass: 'gallery__slideActive',
      },
    }
  },
  components: {
    Swiper,
    SwiperSlide,
    SvgIcon
  },
  directives: {
    swiper: directive
  }
}
</script>

<style lang="scss" scoped>
.gallery {
  position: relative;
  padding: 35px 0 70px;
  overflow: hidden;
  &__text {
    width: calc(100% / 3);
    margin-right: 30px;
    @include below($desktopSml) {
      width: calc(40% - 30px);
    }
    @include below($tablet) {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  &__wrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  &__title {
    line-height: 90%;
    letter-spacing: 0.01em;
    color: #ef850d;
  }
  &__content {
    display: flex;
    width: calc(100% * (2/3));
    @include below($desktopSml) {
      width: 60%;
    }
    @include below($tablet) {
      width: 100%;
    }
  }
  &__slideView {
    margin-top: 20px;
    display: flex;
    width: 100%;
    position: relative;
  }
  &__slide {
    width: 277px;
    height: 700px;
    border-radius: 10px;
    @include below($desktopDef) {
      height: 650px;
    }
    @include below($desktopSml) {
      height: 550px;
    }
    @include below($tablet) {
      width: 610px;
      height: 410px;
    }
    @include below($mobileLrg) {
      height: 300px;
    }
  }
  &__currentSlide {
    margin-top: 20px;
    position: relative;

  }
  &__pagWrap {
    position: absolute;
    top: calc(50% - 37.5px);
    right: -90px;
    z-index: 2;
    @include below($desktopDef) {
      top: 5px;
      right: 10px;
    }
  }
  &__pagination {
    font-weight: 800;
    color: #ef850d;
    font-size: 23px;
    @include below($tablet) {
      width: unset;
    }
  }
  &__next, &__prev {
    position: absolute;
    cursor: pointer;
    top: calc(50% - 10px);
    z-index: 1;
    svg {
      position: relative;
      z-index: 1;
      width: 20px;
      height: 20px;
      @include below(1200) {
        width: 50px;
        height: 50px;
      }
    }
    &:before {
      content: '';
      position: absolute;
      top: -57.5px;
      left: -18px;
      border-radius: 50%;
      width: 140px;
      height: 140px;
      background: rgba(255, 255, 255, 0.5);
      @include below(1200) {
        display: none;
      }
    }
  }
  &__next {
    right: 6px;
  }
  &__prev {
    left: 6px;
    transform: rotate(180deg);
  }
}
</style>

<style>
.swiper-pagination-current {
  font-size: 60px;
}
</style>
