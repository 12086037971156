<template>
  <img :src="`/img/object/${name}.png`"
       :style="{
          'z-index': zIndex,
          'top': top,
          'bottom': bottom,
          'left': left,
          'right': right,
          }"
       :alt="name"
       class="flyingObj"
       :class="{
         'hidden-xl': hiddenXl,
         'hidden-1200': hidden1200,
         'hidden-1440': hidden1440
       }"
  >
</template>

<script>
export default {
  name: "FlyingObj",
  props: {
    name: {
      type: String,
      default() {
        return 'https://offers-api.agregatoreat.ru/api/file/8a9d80ed-1e36-4386-ba47-90307f47587d'
      }
    },
    top: {
      type: String,
      default() {
        return 'unset'
      }
    },
    bottom: {
      type: String,
      default() {
        return 'unset'
      }
    },
    left: {
      type: String,
      default() {
        return 'unset'
      }
    },
    right: {
      type: String,
      default() {
        return 'unset'
      }
    },
    zIndex: {
      type: String,
      default() {
        return '1'
      }
    },
    hiddenXl: {
      type: Boolean,
      default() {
        return false
      }
    },
    hidden1200: {
      type: Boolean,
      default() {
        return false
      }
    },
    hidden1440: {
      type: Boolean,
      default() {
        return false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.flyingObj {
  position: absolute;
}
.hidden-xl {
  @include below($desktopSml) {
    display: none;
  }
}

.hidden-1200 {
  @include below(1200) {
    display: none;
  }
}
.hidden-1440 {
  @include below(1440) {
    display: none;
  }
}
</style>