<template>
  <div class="promo-top">
    <div class="promo-top__item promo-top__text">
      <div class="promo-top__title">{{ promoTitle }}</div>
      <div class="promo-top__descr">{{ promoDescr }}</div>
    </div>
    <div class="promo-top__title promo-top__title--sm">{{ promoTitle }}</div>
    <div class="promo-top__content">
      <div class="promo-top__item promo-top__card"
           @click.prevent="openPopup({
            title: 'Запись на сервис',
            vin: true,
            working: true,
            date: true
          })">
        <div class="promo-top__cardTitle">{{promoList[0].title}}</div>
        <div class="promo-top__cardSubtitle">{{promoList[0].subtitle}}</div>
        <img :src="promoList[0].img" :alt="promoList[0].title" class="promo-top__cardImg">
      </div>
      <div @click.prevent="openPopup" class="promo-top__item promo-top__card">
        <div class="promo-top__cardTitle">{{promoList[1].title}}</div>
        <div class="promo-top__cardSubtitle">{{promoList[1].subtitle}}</div>
        <img :src="promoList[1].img" :alt="promoList[1].title" class="promo-top__cardImg">
      </div>
    </div>
  </div>
</template>

<script>
import {bus} from "../bus";

export default {
  name: "PromoTop",
  props: {
    promoTitle: {
      type: String
    },
    promoDescr: {
      type: String
    },
    promoList: {
      type: Array
    }
  },
  methods: {
    openPopup(obj = {}) {
      bus.$emit('togglePopup', {
        title: 'Оставьте свои контактные данные и мы предложим удобное время для записи',
        btnText: 'Отправить',
        ...obj
      });
    }
  }
}
</script>

<style scoped lang="scss">
.promo-top {
  position: absolute;
  padding: 42px 32px;
  width: 100%;
  height: 200px;
  top: -100px;
  left: 0;
  z-index: 5;
  background: linear-gradient(92.82deg, #2E3036 26.4%, #33353B 86.73%);
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(8px);
  display: flex;
  @include below($tablet) {
    top: -50px;
    padding: 15px !important;
    display: block;
  }
  @include below($desktopSml) {
    padding: 24px 20px;
    background: rgba(0, 0, 0, 0.8);
    width: calc(100% - 34px);
    margin: 0 17px;
  }
  &__text {
    @include below($tablet) {
      display: none;
    }
  }
  &__item {
    flex: 0 1 calc(100% / 2 - 10px);
    margin: 0 10px;
    @include below($tablet) {
      margin-right: 20px;
      margin-left: 0pc;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  &__content {
    display: flex;
    width: 100%;
    @include below($tablet) {
      height: 123px;
    }
  }
  &__title {
    font-weight: bold;
    font-size: 26px;
    line-height: 105%;
    color: #FFFFFF;
    margin-bottom: 10px;
    @include below($desktopSml) {
      font-size: 19px;
    }
    &--sm {
      @include above($tablet) {
        display: none;
      }
    }
  }
  &__descr {
    font-size: 16px;
    line-height: 120%;
    color: #FFFFFF;
  }
  &__card {
    background: #F9F9F9;
    box-shadow: 0px 0px 9px rgba(95, 116, 159, 0.16);
    border-radius: 6px;
    padding: 24px;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    @include below($tablet) {
      padding: 10px;
      font-size: 15px;
    }
  }
  &__cardTitle {
    position: relative;
    z-index: 1;
    font-weight: bold;
    font-size: 18px;
    line-height: 110%;
    color: #33353B;
    @include below($desktopSml) {
      font-size: 16px;
    }
  }
  &__cardSubtitle {
    position: relative;
    z-index: 1;
     font-weight: bold;
     font-size: 18px;
     line-height: 110%;
     color: $additionalColor;
    @include below($desktopSml) {
      font-size: 16px;
    }
  }
  &__cardImg {
    position: absolute;
    right: -10px;
    bottom: 0;
    max-height: 100%;
    object-fit: contain;
  }
}
</style>
